import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, httpOptions } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    isVisible$: any;
    constructor(
        private platform: Platform,
        private keyboard: Keyboard,
        private toastr: ToastrService,
        private router: Router,
        private http: HttpClient,
        private _location: Location
    ) {

        if (this.platform.is('cordova')) {
            this.isVisible$ = this.keyboard.isVisible;
        } else {
            this.isVisible$ = true;
        }


    }

    goBack() {
        this._location.back();
    }

    ValidateSize(file, limit) {
        var FileSize = file.files[0].size / 1024 / 1024; // in MiB

        console.log(FileSize);

        if (FileSize > limit) {
            return false;
        } else {
            return true;
        }
    }

    clearCache() {
        /* we cannot clear the below params when session is terminated/signout - these get set when we close&reopen the app */
        // device_screen_width
        // device_screen_height
        // environment
        // deviceFingerPrint
        // deviceFingerPrintJson
        // platformName
        // storeId

        /* we are clearing these when session is terminated/signout */
        // cartId
        // stepData
        // currentUserProfile
        // productsFilterList
        // wholesaler_user_id
        // wholesaler_company_id
        // loginToken
        // usernameOrEmail
        // company_name

        /* login auth guard data*/
        localStorage.removeItem('cartId');
        localStorage.removeItem('currentUserProfile');
        localStorage.removeItem('loginToken');
        localStorage.removeItem('usernameOrEmail');
        localStorage.removeItem("cartId");
        localStorage.removeItem('wholesaler_company_id');
        localStorage.removeItem('wholesaler_user_id');
        localStorage.removeItem('stepData');
        localStorage.removeItem('productsFilterList');
        localStorage.removeItem('company_name');
        /* login auth guard data*/
    }

    checkValidAuthResponseCode(err: any) {
        //  console.log("i am unauthorised!");

        //console.log(err);
        if (err.status === 401) {
            this.clearCache();
            this.router.navigate(['/signin']);
            return false;
        }
        return true;
    }


    numberOnlyValidation(event: any) {
        const pattern = /[0-9.,]/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }


    getPrimaryMedia(listMedia: any) {


        let primaryImage = listMedia[0].mediaURL;
        for (let i = 0; i < listMedia.length; i++) {
            if (listMedia.isPrimary) {
                primaryImage = listMedia[i].mediaURL;
                return primaryImage;
            }
        }
        return primaryImage;
    }

    getOrdersProductPrimaryMedia(listMedia: any) {


        let primaryImage = listMedia[0].mediaURL;
        for (let i = 0; i < listMedia.length; i++) {
            if (listMedia[i].primary) {
                primaryImage = listMedia[i].basePath + listMedia[i].filename;
                return primaryImage;
            }
        }
        return primaryImage;
    }

}
