//httpConfig.interceptor.ts
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor() {

  }

  private resolveDeviceType(width) {
    let deviceType = "desktop";
    if (width < 600) {
      deviceType = "mobile";
    }
    if (width > 600 && width < 800) {
      deviceType = "tablet";
    }
    localStorage.setItem('device_screen_type', deviceType);
    return deviceType;
    

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if (!request.headers.has('Content-Type')) {
    //   request = request.clone({
    //     setHeaders: {
    //       'content-type': 'application/json'
    //     }
    //   });
    // }


    let modifiedReq = request.clone({
      setHeaders: {
        'device_screen_width': localStorage.getItem("device_screen_width") ? localStorage.getItem("device_screen_width") : '',
        'device_screen_height': localStorage.getItem("device_screen_height") ? localStorage.getItem("device_screen_height") : '',
        'device_screen_type': this.resolveDeviceType(localStorage.getItem("device_screen_width") ? localStorage.getItem("device_screen_width") : 1200),

      }
    });

    return next.handle(modifiedReq).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('event--->>>', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(error);
      }));
  }


}