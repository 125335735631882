import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, httpOptions } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from './common.service';
import { Router } from '@angular/router';

import { CallNumber } from '@ionic-native/call-number/ngx';
@Injectable({
    providedIn: 'root'
})
export class QueryService {

    constructor(private http: HttpClient, public common: CommonService, public router: Router,


        private callNumber: CallNumber,
    ) {


    }


    referWholesaler(data: any): Observable<any> {
        const url = environment.rootCloudUrl + 'api/refer/wholesaler';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }

    raiseQuery(data: any): Observable<any> {
        let requestData = JSON.stringify(data);

        const url = environment.rootCloudUrl + 'api/raise-query';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }

    submitMessage(data: any): Observable<any> {
        let requestData = JSON.stringify(data);

        const url = environment.rootCloudUrl + 'api/send-query-message';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }

    reopen(data: any): Observable<any> {
        let requestData = JSON.stringify({
            query_id: data.id
        });

        const url = environment.rootCloudUrl + "api/reopen-query";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    getQueryDetails(data: any): Observable<any> {
        let requestData = JSON.stringify({

        });

        const url = environment.rootCloudUrl + "api/get-query-detail?query_id=" + data.query_id;
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    searchQueryList(data: any): Observable<any> {
        let requestData = JSON.stringify({

        });

        const url = environment.rootCloudUrl + "api/query-list?search=" + data.search_text;
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    getQueryList(data: any): Observable<any> {
        let requestData = JSON.stringify({

        });

        const url = environment.rootCloudUrl + "api/query-list";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    getQueryAreaList(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/get-areas";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }
    getQuerySubAreaList(id: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/get-subareas?area_id=" + id.id;
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    getQueryContact(id: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/configuration";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }
    appVersion(id: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/app-version";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }
    callNow(number: any) {
        this.callNumber.callNumber(number, true)
            .then(res => console.log('Launched dialer!', res))
            .catch(err => console.log('Error launching dialer', err));
    }

    submitReview(data: any): Observable<any> {
        let requestData = JSON.stringify(data);

        const url = environment.rootCloudUrl + 'api/submit-review';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }

}
