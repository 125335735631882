import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, httpOptions } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import { Product } from '../models/product.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../services/common.service';
import * as $ from "jquery"
import { AuthService } from './auth.service';

import swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class StorageService {

  cart$: any = [];
  constructor(
    private auth: AuthService,
    private storage: Storage,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public common: CommonService

  ) { }


  isInCart(id: any) {
    let flag = 0;
    if (this.cart$.cartProducts) {
      for (var i = 0; i < this.cart$.cartProducts.length; i++) {
        if (this.cart$.cartProducts[i].product_id === id) {
          flag = this.cart$.cartProducts[i].quantity;
        }
      }
    }
    return flag;
  }


  getCartItems() {

    this.getStorage('my-cart').then((products) => {
      this.cart$.cartProducts = products;
      this.cart$.cartArr = products;
      if (!this.cart$.cartProducts || this.cart$.cartProducts.length === 0) {
        this.cart$.cartProducts = [];
        this.cart$.cartSize = 0;
        return;
      }
      this.cart$.cartSize = this.cart$.cartProducts.length;
    });

  }



  getStorage(ITEMS_KEY): Promise<any[]> {
    return this.storage.get(ITEMS_KEY);
  }






  addProductToCart(product: any) {
    let cartProducts = {
      product_id: product.id,
      wholesaler_user_id: this.auth.user$.id,
      quantity: (product.minimum_buying_quantity) ? (product.minimum_buying_quantity) : 1,
      minimum_buying_quantity: (product.minimum_buying_quantity) ? (product.minimum_buying_quantity) : 1
    }

    if (this.auth.user$.role_id === 7 || this.auth.user$.role_id === 8 || this.auth.user$.role_id === 11) {
      cartProducts.wholesaler_user_id = localStorage.getItem('wholesaler_user_id')
    }

    if (+((product.minimum_buying_quantity) ? (product.minimum_buying_quantity) : 1) > product.available_quantity) {
      this.toastr.warning("Product quantity is greater than available quantity!");
      return;
    } else {
      // Save cart product in storage
      this.setStorageValue(cartProducts, 'my-cart');
    }

  }

  addQtyProductToCart(product: any, qty: number) {
    let cartProducts = {
      product_id: product.id,
      wholesaler_user_id: this.auth.user$.id,
      quantity: (qty) ? (qty) : 1,
      minimum_buying_quantity: (product.minimum_buying_quantity) ? (product.minimum_buying_quantity) : 1
    }

    if (this.auth.user$.role_id === 11) {
      cartProducts.minimum_buying_quantity = 1;
    }

    if (this.auth.user$.role_id === 7 || this.auth.user$.role_id === 8 || this.auth.user$.role_id === 11) {
      cartProducts.wholesaler_user_id = localStorage.getItem('wholesaler_user_id')
    }

    // if (+((product.minimum_buying_quantity) ? (product.minimum_buying_quantity) : 1) > product.available_quantity) {
    //   this.toastr.warning("Product quantity is greater than available quantity!");
    //   return;
    // } else {
    //   // Save cart product in storage
    //   this.setStorageValue(cartProducts, 'my-cart');
    // }

    this.setQtyStorageValue(cartProducts, 'my-cart', qty);



  }
  incrementProductToCart(product: any) {

    if (!product.available_quantity) {
      this.toastr.info("OUT OF STOCK");
      return;
    }

    let cartProducts = {
      wholesaler_user_id: this.auth.user$.id,
      product_id: (product.id) ? (product.id) : (product.product_id),
      quantity: (product.minimum_buying_quantity) ? (product.minimum_buying_quantity) : 1,
      available_quantity: (product.available_quantity) ? (product.available_quantity) : 0,
      minimum_buying_quantity: (product.minimum_buying_quantity) ? (product.minimum_buying_quantity) : 1
    }

    if (this.auth.user$.role_id === 7 || this.auth.user$.role_id === 8 || this.auth.user$.role_id === 11) {
      cartProducts.wholesaler_user_id = localStorage.getItem('wholesaler_user_id');
    }

    // Save cart product in storage
    this.incrementStorageValue(cartProducts, 'my-cart');

  }

  decrementProductToCart(product: any) {

    if (!product.available_quantity) {
      this.toastr.info("OUT OF STOCK");
      return;
    }

    let cartProducts = {
      wholesaler_user_id: this.auth.user$.id,
      product_id: (product.id) ? (product.id) : (product.product_id),
      quantity: (product.minimum_buying_quantity) ? (product.minimum_buying_quantity) : 1,
      minimum_buying_quantity: (product.minimum_buying_quantity) ? (product.minimum_buying_quantity) : 1
    }

    if (this.auth.user$.role_id === 7 || this.auth.user$.role_id === 8 || this.auth.user$.role_id === 11) {
      cartProducts.wholesaler_user_id = localStorage.getItem('wholesaler_user_id');
    }

    // Save cart product in storage
    this.decrementStorageValue(cartProducts, 'my-cart');

  }

  clearCart() {


    swal
      .fire({
        title: 'Clear Cart',
        position: 'top',
        text: "Are you sure?",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      })


      .then((result) => {
        if (result.value) {
          let cartProducts = {
            wholesaler_user_id: this.auth.user$.id,
          }

          if (this.auth.user$.role_id === 7 || this.auth.user$.role_id === 8 || this.auth.user$.role_id === 11) {
            cartProducts.wholesaler_user_id = localStorage.getItem('wholesaler_user_id');
          }

          // Save cart product in storage
          this.clearCartStorageValue('my-cart');
        }





      });



  }

  incrementQtyProductToCart(product: any, qty: number) {



    let cartProducts = {
      wholesaler_user_id: this.auth.user$.id,
      product_id: (product.id) ? (product.id) : (product.product_id),
      quantity: (product.minimum_buying_quantity) ? (product.minimum_buying_quantity) : 1,
      available_quantity: (product.available_quantity) ? (product.available_quantity) : 0,
      minimum_buying_quantity: (product.minimum_buying_quantity) ? (product.minimum_buying_quantity) : 1
    }

    if (this.auth.user$.role_id === 7 || this.auth.user$.role_id === 8 || this.auth.user$.role_id === 11) {
      cartProducts.wholesaler_user_id = localStorage.getItem('wholesaler_user_id');
    }

    // Save cart product in storage
    this.incrementQtyStorageValue(cartProducts, 'my-cart', qty);

  }

  decrementQtyProductToCart(product: any, qty: number) {
    let cartProducts = {
      wholesaler_user_id: this.auth.user$.id,
      product_id: (product.id) ? (product.id) : (product.product_id),
      quantity: (product.minimum_buying_quantity) ? (product.minimum_buying_quantity) : 1,
      minimum_buying_quantity: (product.minimum_buying_quantity) ? (product.minimum_buying_quantity) : 1
    }



    if (this.auth.user$.role_id === 7 || this.auth.user$.role_id === 8 || this.auth.user$.role_id === 11) {
      cartProducts.wholesaler_user_id = localStorage.getItem('wholesaler_user_id');
    }

    // Save cart product in storage
    this.decrementQtyStorageValue(cartProducts, 'my-cart', qty);

  }
  updateQtyProductToCart(product: any, qty: number) {



    let cartProducts = {
      wholesaler_user_id: this.auth.user$.id,
      product_id: (product.id) ? (product.id) : (product.product_id),
      quantity: (product.minimum_buying_quantity) ? (product.minimum_buying_quantity) : 1,
      available_quantity: (product.available_quantity) ? (product.available_quantity) : 0,
      minimum_buying_quantity: (product.minimum_buying_quantity) ? (product.minimum_buying_quantity) : 1
    }

    if (this.auth.user$.role_id === 11) {
      cartProducts.minimum_buying_quantity = 1;
    }

    if (this.auth.user$.role_id === 7 || this.auth.user$.role_id === 8 || this.auth.user$.role_id === 11) {
      cartProducts.wholesaler_user_id = localStorage.getItem('wholesaler_user_id');
    }

    // Save cart product in storage
    this.updateQtyStorageValue(cartProducts, 'my-cart', qty);

  }

  setStorageValue(product: any, ITEMS_KEY): Promise<any> {
    return this.storage.get(ITEMS_KEY).then((items: any[]) => {


      if (items) {

        let isNew = true;
        let newItems: any[] = [];
        let k = 0;
        for (let i of items) {

          if (i.product_id === product.product_id) {



            let newQuantity = 0;
            //minimum buying qty check
            if (product.minimum_buying_quantity === 0) {
              newQuantity = (+i.quantity) + 1;
            } else {
              newQuantity = (+i.quantity) + (+product.minimum_buying_quantity);
            }

            if (this.auth.user$.role_id === 11) {
              newQuantity = (+i.quantity) + 1;
            }


            i.quantity = newQuantity;
            isNew = false;
            product.quantity = newQuantity;
            product = product;
          }


          k++;
        }

        if (isNew) {
          items.push(product);
        }

        newItems = items;

        let cartId = "";
        if (localStorage.getItem('cartId')) {
          cartId = localStorage.getItem('cartId');
        }


        let data = {
          'product_id': product.product_id,
          'quantity': product.quantity,
          'wholesaler_user_id': product.wholesaler_user_id
        };


        this.addToCart(data).subscribe((res: any) => {


          this.spinner.hide();
          this.toastr.success(res.message);

          //nandan
          //return this.storage.set(ITEMS_KEY, items);
          // setTimeout(() => {
          //   this.updateCartArr(res);
          // }, 1000);
          if (typeof res.data == "undefined") {
            res.data.cart_items = [];
          }
          this.updateCartArr(res);


          return this.storage.set(ITEMS_KEY, res.data.cart_items);

          // return this.storage.set(ITEMS_KEY, res);
        },
          (err: any) => {
            console.log(err.error);
            this.spinner.hide();
            if (!this.common.checkValidAuthResponseCode(err)) {
              return;
            }
            if (err.error.text) {
              this.toastr.success(err.error.text);
            } else {
              this.toastr.success(err.error.message);
            }


          }
        );



      } else {
        //console.log(ITEMS_KEY);


        let cartId = "";
        if (localStorage.getItem('cartId')) {
          cartId = localStorage.getItem('cartId');
        }

        let data = {
          'product_id': product.product_id,
          'quantity': product.quantity,
          'wholesaler_user_id': product.wholesaler_user_id
        };
        this.addToCart(data).subscribe((res: any) => {
          this.spinner.hide();
          if (res.cartId) {
            localStorage.setItem('cartId', res.cartId);
          }
          this.toastr.success(res.message);
          //nandan
          // return this.storage.set(ITEMS_KEY, [data]);
          // setTimeout(() => {
          //   this.updateCartArr(res);
          // }, 1000);

          if (typeof res.data == "undefined") {
            res.data.cart_items = [];
          }
          this.updateCartArr(res);
          return this.storage.set(ITEMS_KEY, res.data.cart_items);
        },
          (err: any) => {
            console.log(err.error);
            this.spinner.hide();
            if (!this.common.checkValidAuthResponseCode(err)) {
              return;
            }

            if (err.error.text) {
              this.toastr.success(err.error.text);
            } else {
              this.toastr.success(err.error.message);
            }


          }
        );




      }

      setTimeout(() => {
        this.getCartItems();
      }, 1000);

    });
  }
  getTotalQty(cartItems: any) {
    let totalQty = 0;
    if (cartItems) {
      if (cartItems.length) {
        cartItems.forEach(function (item, index) {
          totalQty = totalQty + item.quantity;
        });
      }
    }

    return totalQty;
  }
  setQtyStorageValue(product: any, ITEMS_KEY, qty: number): Promise<any> {
    return this.storage.get(ITEMS_KEY).then((items: any[]) => {


      if (items) {

        let isNew = true;
        let newItems: any[] = [];
        let k = 0;
        for (let i of items) {

          if (i.product_id === product.product_id) {



            let newQuantity = 0;

            // if (product.minimum_buying_quantity === 0) {
            //   newQuantity = (+i.quantity) + 1;
            // } else {
            //   newQuantity = (+i.quantity) + (+product.minimum_buying_quantity);
            // }
            newQuantity = (+i.quantity) + qty;
            i.quantity = newQuantity;
            isNew = false;
            product.quantity = newQuantity;
            product = product;
          }


          k++;
        }

        if (isNew) {
          items.push(product);
        }

        newItems = items;

        let cartId = "";
        if (localStorage.getItem('cartId')) {
          cartId = localStorage.getItem('cartId');
        }


        let data = {
          'product_id': product.product_id,
          'quantity': product.quantity,
          'wholesaler_user_id': product.wholesaler_user_id
        };


        this.addToCart(data).subscribe((res: any) => {


          this.spinner.hide();
          this.toastr.success(res.message);

          //nandan
          //return this.storage.set(ITEMS_KEY, items);
          // setTimeout(() => {
          //   this.updateCartArr(res);
          // }, 1000);
          if (typeof res.data == "undefined") {
            res.data.cart_items = [];
          }
          this.updateCartArr(res);


          return this.storage.set(ITEMS_KEY, res.data.cart_items);

          // return this.storage.set(ITEMS_KEY, res);
        },
          (err: any) => {
            console.log(err.error);
            this.spinner.hide();
            if (!this.common.checkValidAuthResponseCode(err)) {
              return;
            }
            if (err.error.text) {
              this.toastr.success(err.error.text);
            } else {
              this.toastr.success(err.error.message);
            }


          }
        );



      } else {
        //console.log(ITEMS_KEY);


        let cartId = "";
        if (localStorage.getItem('cartId')) {
          cartId = localStorage.getItem('cartId');
        }

        let data = {
          'product_id': product.product_id,
          'quantity': product.quantity,
          'wholesaler_user_id': product.wholesaler_user_id
        };
        this.addToCart(data).subscribe((res: any) => {
          this.spinner.hide();
          if (res.cartId) {
            localStorage.setItem('cartId', res.cartId);
          }
          this.toastr.success(res.message);
          //nandan
          // return this.storage.set(ITEMS_KEY, [data]);
          // setTimeout(() => {
          //   this.updateCartArr(res);
          // }, 1000);

          if (typeof res.data == "undefined") {
            res.data.cart_items = [];
          }
          this.updateCartArr(res);
          return this.storage.set(ITEMS_KEY, res.data.cart_items);
        },
          (err: any) => {
            console.log(err.error);
            this.spinner.hide();
            if (!this.common.checkValidAuthResponseCode(err)) {
              return;
            }

            if (err.error.text) {
              this.toastr.success(err.error.text);
            } else {
              this.toastr.success(err.error.message);
            }


          }
        );




      }

      setTimeout(() => {
        this.getCartItems();
      }, 1000);

    });
  }

  incrementStorageValue(product: any, ITEMS_KEY): Promise<any> {
    return this.storage.get(ITEMS_KEY).then((items: any[]) => {
      this.spinner.show();

      if (items) {

        let isNew = true;
        let newItems: any[] = [];
        let k = 0;
        for (let i of items) {

          if (i.product_id === product.product_id) {


            let newQuantity = 0;
            //minimum buying qty check
            if (product.minimum_buying_quantity === 0) {
              newQuantity = (+i.quantity) + 1;
            } else {
              newQuantity = (+i.quantity) + (+product.minimum_buying_quantity);
            }
            if (this.auth.user$.role_id === 11) {
              newQuantity = (+i.quantity) + 1;
            }

            i.quantity = newQuantity;
            isNew = false;
            product.quantity = newQuantity;
            product = product;
          }


          k++;
        }

        if (isNew) {
          items.push(product);
        }

        newItems = items;

        let cartId = "";
        if (localStorage.getItem('cartId')) {
          cartId = localStorage.getItem('cartId');
        }


        let data = {
          'product_id': product.product_id,
          'quantity': product.quantity,
          'wholesaler_user_id': product.wholesaler_user_id
        };



        if (+(product.quantity) > product.available_quantity) {
          this.toastr.warning("Product quantity is greater than available quantity!");
          this.spinner.hide();
          return;
        } else {
          this.updateCart(data).subscribe((res: any) => {
            //
            this.toastr.success(res.message);

            //nandan
            // this.updateCartArr(res);

            if (typeof res.data == "undefined") {
              res.data.cart_items = [];
            }
            this.updateCartArr(res);
            return this.storage.set(ITEMS_KEY, res.data.cart_items);
            //return this.storage.set(ITEMS_KEY, newItems);

            // return this.storage.set(ITEMS_KEY, res);
          },
            (err: any) => {
              console.log(err.error);
              // this.spinner.hide();
              if (!this.common.checkValidAuthResponseCode(err)) {
                return;
              }
              if (err.error.text) {
                this.toastr.success(err.error.text);
              } else {
                this.toastr.success(err.error.message);
              }


            }
          );


        }




      }

      setTimeout(() => {

        this.getCartItems();
        this.spinner.hide();
      }, 1000);

    });
  }



  clearCartStorageValue(ITEMS_KEY) {
    let data = {
      'wholesaler_user_id': this.auth.user$.id
    };
    if (this.auth.user$.role_id === 7 || this.auth.user$.role_id === 8 || this.auth.user$.role_id === 11) {
      data = {
        'wholesaler_user_id': localStorage.getItem('wholesaler_user_id')
      };
    }
    this.removeWholeCart(data).subscribe((res: any) => {
      // this.spinner.hide();
      this.toastr.success(res.message);

      //nandan
      setTimeout(() => {
        this.updateCartArr(res);
      }, 1000);

      if (typeof res.data == "undefined") {
        res.data.cart_items = [];

      }
      if (typeof res.data.cart_items == "undefined") {
        res.data.cart_items = [];

      }
      this.updateCartArr(res);
      return this.storage.set(ITEMS_KEY, res.data.cart_items);
      //return this.storage.set(ITEMS_KEY, newItems);
      // return this.storage.set(ITEMS_KEY, res);
    },
      (err: any) => {
        console.log(err.error);
        //  this.spinner.hide();
        if (!this.common.checkValidAuthResponseCode(err)) {
          return;
        }
        if (err.error.text) {
          this.toastr.success(err.error.text);
        } else {
          this.toastr.success(err.error.message);
        }


      }
    );

  }


  decrementStorageValue(product: any, ITEMS_KEY): Promise<any> {
    return this.storage.get(ITEMS_KEY).then((items: any[]) => {
      this.spinner.show();


      if (items) {

        let isNew = true;
        let newItems: any[] = [];
        let k = 0;
        for (let i of items) {

          if (i.product_id === product.product_id) {

            let newQuantity = 0;
            //minimum buying qty check
            if (product.minimum_buying_quantity === 0) {
              newQuantity = (+i.quantity) - 1;
            } else {
              newQuantity = (+i.quantity) - (+product.minimum_buying_quantity);
            }
            if (this.auth.user$.role_id === 11) {
              newQuantity = (+i.quantity) - 1;
            }
            i.quantity = newQuantity;
            isNew = false;
            product.quantity = newQuantity;
            product = product;
          }


          k++;
        }

        if (isNew) {
          items.push(product);
        }

        newItems = items;

        let cartId = "";
        if (localStorage.getItem('cartId')) {
          cartId = localStorage.getItem('cartId');
        }


        let data = {
          'product_id': product.product_id,
          'quantity': product.quantity,
          'wholesaler_user_id': product.wholesaler_user_id
        };
        console.log(data);
        this.updateCart(data).subscribe((res: any) => {
          // this.spinner.hide();
          this.toastr.success(res.message);

          //nandan
          setTimeout(() => {
            this.updateCartArr(res);
          }, 1000);

          if (typeof res.data == "undefined") {
            res.data.cart_items = [];
          }
          this.updateCartArr(res);
          return this.storage.set(ITEMS_KEY, res.data.cart_items);
          //return this.storage.set(ITEMS_KEY, newItems);
          // return this.storage.set(ITEMS_KEY, res);
        },
          (err: any) => {
            console.log(err.error);
            //  this.spinner.hide();
            if (!this.common.checkValidAuthResponseCode(err)) {
              return;
            }
            if (err.error.text) {
              this.toastr.success(err.error.text);
            } else {
              this.toastr.success(err.error.message);
            }


          }
        );



      }

      setTimeout(() => {
        this.getCartItems();
        this.spinner.hide();

      }, 1000);

    });
  }



  incrementQtyStorageValue(product: any, ITEMS_KEY, qty: number): Promise<any> {
    return this.storage.get(ITEMS_KEY).then((items: any[]) => {
      this.spinner.show();

      if (items) {

        let isNew = true;
        let newItems: any[] = [];
        let k = 0;
        for (let i of items) {

          if (i.product_id === product.product_id) {


            let newQuantity = 0;

            // if (product.minimum_buying_quantity === 0) {
            //   newQuantity = (+i.quantity) + 1;
            // } else {
            //   newQuantity = (+i.quantity) + (+product.minimum_buying_quantity);
            // }

            newQuantity = (+i.quantity) + qty;

            i.quantity = newQuantity;
            isNew = false;
            product.quantity = newQuantity;
            product = product;
          }


          k++;
        }

        if (isNew) {
          items.push(product);
        }

        newItems = items;

        let cartId = "";
        if (localStorage.getItem('cartId')) {
          cartId = localStorage.getItem('cartId');
        }


        let data = {
          'product_id': product.product_id,
          'quantity': product.quantity,
          'wholesaler_user_id': product.wholesaler_user_id
        };



        // if (+(product.quantity) > product.available_quantity) {
        if (0) {
          this.toastr.warning("Product quantity is greater than available quantity!");
          this.spinner.hide();
          return;
        } else {
          this.updateCart(data).subscribe((res: any) => {
            //
            this.toastr.success(res.message);

            //nandan
            // this.updateCartArr(res);

            if (typeof res.data == "undefined") {
              res.data.cart_items = [];
            }
            this.updateCartArr(res);
            return this.storage.set(ITEMS_KEY, res.data.cart_items);
            //return this.storage.set(ITEMS_KEY, newItems);

            // return this.storage.set(ITEMS_KEY, res);
          },
            (err: any) => {
              console.log(err.error);
              // this.spinner.hide();
              if (!this.common.checkValidAuthResponseCode(err)) {
                return;
              }
              if (err.error.text) {
                this.toastr.success(err.error.text);
              } else {
                this.toastr.success(err.error.message);
              }


            }
          );


        }




      }

      setTimeout(() => {

        this.getCartItems();
        this.spinner.hide();
      }, 1000);

    });
  }


  decrementQtyStorageValue(product: any, ITEMS_KEY, qty: number): Promise<any> {
    return this.storage.get(ITEMS_KEY).then((items: any[]) => {
      this.spinner.show();


      if (items) {

        let isNew = true;
        let newItems: any[] = [];
        let k = 0;
        for (let i of items) {

          if (i.product_id === product.product_id) {

            let newQuantity = 0;

            // if (product.minimum_buying_quantity === 0) {
            //   newQuantity = (+i.quantity) - 1;
            // } else {
            //   newQuantity = (+i.quantity) - (+product.minimum_buying_quantity);
            // }

            newQuantity = (+i.quantity) - qty;


            i.quantity = newQuantity;
            isNew = false;
            product.quantity = newQuantity;
            product = product;
          }


          k++;
        }

        if (isNew) {
          items.push(product);
        }

        newItems = items;

        let cartId = "";
        if (localStorage.getItem('cartId')) {
          cartId = localStorage.getItem('cartId');
        }


        let data = {
          'product_id': product.product_id,
          'quantity': product.quantity,
          'wholesaler_user_id': product.wholesaler_user_id
        };
        console.log(data);
        this.updateCart(data).subscribe((res: any) => {
          // this.spinner.hide();
          this.toastr.success(res.message);

          //nandan
          setTimeout(() => {
            this.updateCartArr(res);
          }, 1000);

          if (typeof res.data == "undefined") {
            res.data.cart_items = [];
          }
          this.updateCartArr(res);
          return this.storage.set(ITEMS_KEY, res.data.cart_items);
          //return this.storage.set(ITEMS_KEY, newItems);
          // return this.storage.set(ITEMS_KEY, res);
        },
          (err: any) => {
            console.log(err.error);
            //  this.spinner.hide();
            if (!this.common.checkValidAuthResponseCode(err)) {
              return;
            }
            if (err.error.text) {
              this.toastr.success(err.error.text);
            } else {
              this.toastr.success(err.error.message);
            }


          }
        );



      }

      setTimeout(() => {
        this.getCartItems();
        this.spinner.hide();

      }, 1000);

    });
  }
  updateQtyStorageValue(product: any, ITEMS_KEY, qty: number): Promise<any> {


    return this.storage.get(ITEMS_KEY).then((items: any[]) => {
      this.spinner.show();




      let data = {
        'product_id': product.product_id,
        'quantity': product.quantity,
        'wholesaler_user_id': product.wholesaler_user_id,
        'total_available_quantity': product.available_quantity
      };



      // if (+(product.quantity) > product.available_quantity) {
      this.updateSingleItemCart(data).subscribe((res: any) => {
        //
        this.toastr.success(res.message);

        //nandan
        // this.updateCartArr(res);

        if (typeof res.data == "undefined") {
          res.data.cart_items = [];
        }
        this.updateCartArr(res);
        return this.storage.set(ITEMS_KEY, res.data.cart_items);
        //return this.storage.set(ITEMS_KEY, newItems);

        // return this.storage.set(ITEMS_KEY, res);
      },
        (err: any) => {
          console.log(err.error);
          // this.spinner.hide();
          if (!this.common.checkValidAuthResponseCode(err)) {
            return;
          }
          if (err.error.text) {
            this.toastr.success(err.error.text);
          } else {
            this.toastr.success(err.error.message);
          }


        }
      );

      setTimeout(() => {

        this.getCartItems();
        this.spinner.hide();
      }, 1000);

    });
  }
  likeUnlikeProduct(product: any) {



    this.spinner.show();
    let data = {
      'product_id': product
    };
    console.log(data);
    this.likeUnlike(data).subscribe((res: any) => {
      this.spinner.hide();
      if ($('#' + product).attr('name') === 'heart') {
        $('#' + product).attr('name', "heart-outline");
      } else {
        $('#' + product).attr('name', "heart");
      }

      this.toastr.success(res.message);
    },
      (err: any) => {
        console.log(err.error);
        this.spinner.hide();
        if (!this.common.checkValidAuthResponseCode(err)) {
          return;
        }
        if (err.error.text) {
          this.toastr.success(err.error.text);
        } else {
          this.toastr.success(err.error.message);
        }


      }
    );
  }


  removeStorageValue(id: number, ITEMS_KEY): Promise<Product> {
    return this.storage.get(ITEMS_KEY).then((items: any[]) => {

      if (!items || items.length === 0) {
        return null;
      }
      let data = {
        'product_id': id
      };

      this.spinner.show();
      this.removeFromCart(data).subscribe((res: any) => {
        // this.spinner.hide();
        this.toastr.success(res.message);


        let k = 0;
        for (let i of items) {
          if (i.product_id === id) {
            items.splice(k, 1);
          }
          k++;
        }

        // setTimeout(() => {
        //   this.getCartItems();
        // }, 1000);




        if (!items || items.length === 0) {
          return this.storage.remove(ITEMS_KEY);
        } else {
          // return this.storage.set(ITEMS_KEY, items);
          // this.updateCartArr(res);
          if (typeof res.data == "undefined") {
            return this.storage.set(ITEMS_KEY, []);
          } else {

            this.updateCartArr(res);
            return this.storage.set(ITEMS_KEY, res.data.cart_items);
          }

        }

        //nandan
        // return this.storage.set(ITEMS_KEY, res);
      },
        (err: any) => {
          console.log(err.error);
          //  this.spinner.hide();
          if (!this.common.checkValidAuthResponseCode(err)) {
            return;
          }
          if (err.error.text) {
            this.toastr.success(err.error.text);
          } else {
            this.toastr.success(err.error.message);
          }


        }
      );

      setTimeout(() => {
        this.getCartItems();
        this.spinner.hide();
      }, 1000);

    });
  }




  removeFromOrder(data: any): Observable<any> {

    let requestData = {
      "order_item_id": data.id
    };

    const url = environment.rootCloudUrl + "api/remove-order-item";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
    };
    return this.http.post(url, requestData, httpOptions)
      .pipe(
        map((response: Response) => response)
      )
  }





  preOrder(id: any, data: any): Observable<any> {

    let requestData = {
      "master_wholesaler_user_id": id,
      "product_ids": data.id
    };

    const url = environment.rootCloudUrl + "api/pre-order";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
    };
    return this.http.post(url, requestData, httpOptions)
      .pipe(
        map((response: Response) => response)
      )
  }



  addToCart(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "product_id": data.product_id,
      "quantity": data.quantity,
      'wholesaler_user_id': data.wholesaler_user_id
    }
    );


    if (this.auth.user$.role_id === 7 || this.auth.user$.role_id === 8 || this.auth.user$.role_id === 11) {
      requestData = JSON.stringify({
        "product_id": data.product_id,
        "quantity": data.quantity,
        'wholesaler_user_id': localStorage.getItem('wholesaler_user_id')
      }
      );
    }


    const url = environment.rootCloudUrl + "api/add-to-cart";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
    };
    return this.http.post(url, requestData, httpOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  removeFromCart(data: any): Observable<any> {

    if (this.auth.user$.role_id === 7 || this.auth.user$.role_id === 8 || this.auth.user$.role_id === 11) {
      data.wholesaler_user_id = localStorage.getItem('wholesaler_user_id');
    } else {
      data.wholesaler_user_id = this.auth.user$.id;
    }
    let requestData = JSON.stringify({
      "product_id": data.product_id,
      "wholesaler_user_id": data.wholesaler_user_id
    }
    );

    const url = environment.rootCloudUrl + "api/remove-cart-item";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
    };
    return this.http.post(url, requestData, httpOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  updateSingleItemCart(data: any): Observable<any> {
    let requestData = data;
    const url = environment.rootCloudUrl + "api/update-cart-with-single-item-quantity";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
    };
    return this.http.post(url, requestData, httpOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  updateCart(data: any): Observable<any> {
    let requestData = data;
    const url = environment.rootCloudUrl + "api/update-cart-item-quantity";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
    };
    return this.http.post(url, requestData, httpOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  removeWholeCart(data: any): Observable<any> {
    let requestData = data;
    const url = environment.rootCloudUrl + "api/remove-whole-cart";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
    };
    return this.http.post(url, requestData, httpOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  likeUnlike(data: any): Observable<any> {
    let requestData = JSON.stringify(data);
    const url = environment.rootCloudUrl + "api/product/like-unlike";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
    };
    return this.http.post(url, requestData, httpOptions)
      .pipe(
        map((response: Response) => response)
      )
  }






  async setObject(product: Product, ITEMS_KEY) {
    await this.storage.get(ITEMS_KEY).then(async (products: any) => {

      if (products.value) {
        let newProducts: any = JSON.parse(products.value);
        newProducts.push(product);

        await this.storage.set(
          ITEMS_KEY,
          JSON.stringify(newProducts)
        );
      } else {
        await this.storage.set(
          ITEMS_KEY,
          JSON.stringify([product])
        );
      }
    })
  }

  async getObject(ITEMS_KEY) {
    const ret: any = await this.storage.get(ITEMS_KEY);
    let products: any = [];
    products = JSON.parse(ret.value);

    return products;
  }


  async clear() {
    //await this.storage.clear();
    this.cart$ = [];
    await this.storage.remove("my-cart");

  }


  cartArr: any;

  updateCartArr(res: any) {

    this.cart$.cartProducts = res.data.cart_items;
    this.cart$.cartDataArr = res.data;
    this.cart$.cartArr = res.data.cart_items;
    this.cart$.credit_limit = res.data.credit_limit;
    this.cart$.loyalty_points = res.data.loyalty_points;
    this.cart$.sub_total = res.data.sub_total;
    this.cart$.grand_total = res.data.grand_total;
    this.cart$.cartSize = res.data.cart_items.length;
  }

  viewCartItems() {
    let data = {};
    //this.spinner.show();
    this.viewCart(data).subscribe((res: any) => {
      this.updateCartArr(res);
      // this.storage.set("my-cart", this.cartArr);
      // localStorage.setItem('cartId', this.cartArr.cartId);


    },
      (err: any) => {
        console.log(err);
        if (!this.common.checkValidAuthResponseCode(err)) {
          return;
        }
        this.cartArr = [];
        //   this.storage.remove("my-cart");
        //  localStorage.removeItem("cartId");
      })
  }


  viewCart(data: any): Observable<any> {
    let requestData = {};
    const url = environment.rootCloudUrl + "api/view-cart";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
    };
    return this.http.post(url, requestData, httpOptions)
      .pipe(
        map((response: Response) => response)
      )
  }
}
