
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { QueryService } from 'src/app/services/query.service';
import { StoreService } from 'src/app/services/store.service';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { Router } from '@angular/router';
@Component({
  selector: 'app-redeem-points',
  templateUrl: './redeem-points.component.html',
  styleUrls: ['./redeem-points.component.scss'],
})
export class RedeemPointsComponent implements OnInit {

  constructor(

    private router: Router,

    private auth: AuthService,
    private store: StoreService,
    private toastr: ToastrService,
    public query: QueryService,
    private spinner: NgxSpinnerService,
    private common: CommonService,
    private modalController: ModalController,
    private theInAppBrowser: InAppBrowser) { }

  ngOnInit() { }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  points = {
    value: 0
  };
  redeemPoints(form: any) {
    console.log(form.value);
    if (!form.value) {
      this.toastr.warning("Invalid Redeem Points!");
      return;
    }

    const data = {
      "amount": form.value.points
    };
    // this.router.navigate(['loyalty-points']);

    this.spinner.show();
    this.store.redeemPoints(data).subscribe((res: any) => {
      this.spinner.hide();
      if (!res) {
        this.toastr.error("Invalid authentication!");
        return;
      }
      // if (res.status === 200) {
      //   this.toastr.success(res.message);
      //   this.modalController.dismiss({
      //     'dismissed': true
      //   })
      // } else {
      //   this.toastr.error(res.message);
      // }
      this.modalController.dismiss({
        'dismissed': true
      })
      this.toastr.info(res.ErrorMessage);

    },
      (err: any) => {
        this.spinner.hide();

        if (!this.common.checkValidAuthResponseCode(err)) {
          return;
        }
        if (err.error.text) {
          this.toastr.warning(err.error.text);
        } else {
          this.toastr.warning(err.error.message);
        }
      }
    );


  }
  async dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    })

  }

}
