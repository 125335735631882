import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { AuthService } from '../../../../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../../../../services/common.service';
import { StoreService } from '../../../../services/store.service';
import * as $ from "jquery";
import { IonicSelectableComponent } from 'ionic-selectable';


@Component({
  selector: 'app-add-team',
  templateUrl: './add-team.component.html',
  styleUrls: ['./add-team.component.scss'],
})

export class AddTeamComponent implements OnInit {
  selectCountryCodeOptions: any;

  @Input("companyId") companyId;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private modalController: ModalController,
    public toastController: ToastController,
    private toastr: ToastrService,
    public common: CommonService,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
    private store: StoreService,
  ) { }
  company: any = {};
  countriesList: any;
  ngOnInit() {
    this.getCountriesList();

    this.getStatesList(101);
    this.company.gst_number = null;
    this.company.pan_no = null;
    this.company.aadhar_no = null;

  }
  statesList: any;
  citiesList: any;
  address: any = [];
  public getCountriesList() {
    const data = {
    };

    this.spinner.show();
    this.auth.getCountriesList(data).subscribe((res: any) => {
      this.spinner.hide();
      if (!res) {
        this.toastr.error("Invalid authentication!");
        return;
      }

      if (res.status === 200) {

        // this.toastr.success(res.message);
        this.countriesList = res.data;
        this.address.country_id = 101;
      } else {
        //  this.toastr.error(res.message);
        // this.companyStep1Arr = {};
      }
    },
      (err: any) => {
        this.spinner.hide();

        if (!this.common.checkValidAuthResponseCode(err)) {
          return;
        }
        if (err.error.text) {
          this.toastr.warning(err.error.text);
        } else {
          this.toastr.warning(err.error.message);
        }
        //  this.router.navigate(['home']);
      }
    );
  }

  public getStatesList(country) {

    console.log(country);

    this.spinner.show();
    this.auth.getStatesList(country).subscribe((res: any) => {
      this.spinner.hide();
      if (!res) {
        this.toastr.error("Invalid authentication!");
        return;
      }

      if (res.status === 200) {
        // this.toastr.success(res.message);
        this.statesList = res.data;
        console.log(this.statesList);
      } else {
        this.statesList = [];
        //  this.toastr.error(res.message);
        // this.companyStep1Arr = {};
      }
    },
      (err: any) => {
        this.spinner.hide();

        if (!this.common.checkValidAuthResponseCode(err)) {
          return;
        }
        if (err.error.text) {
          this.toastr.warning(err.error.text);
        } else {
          this.toastr.warning(err.error.message);
        }
        //  this.router.navigate(['home']);
      }
    );
  }

  public getCitiesList(state) {

    console.log(state);

    this.spinner.show();
    this.auth.getCitiesList(state).subscribe((res: any) => {
      this.spinner.hide();
      if (!res) {
        this.toastr.error("Invalid authentication!");
        return;
      }

      if (res.status === 200) {
        // this.toastr.success(res.message);
        this.citiesList = res.data;

        console.log(this.citiesList);
      } else {
        this.citiesList = [];
        //  this.toastr.error(res.message);
        // this.companyStep1Arr = {};
      }
    },
      (err: any) => {
        this.spinner.hide();

        if (!this.common.checkValidAuthResponseCode(err)) {
          return;
        }
        if (err.error.text) {
          this.toastr.warning(err.error.text);
        } else {
          this.toastr.warning(err.error.message);
        }
        //  this.router.navigate(['home']);
      }
    );
  }



  // Back to previous page function
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    })
  }
  // Go to cart page function
  async goToStepper() {
    this.router.navigate(['/personalDetails2']);

  }
  profileArr: any = [];

  public uploadImage(event: any): void {

    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      /*shrink*/

      // Ensure it's an image
      if (file.type.match(/image.*/)) {
        console.log('An image has been loaded');

        // Load the image
        var reader = new FileReader();
        reader.onload = function (readerEvent: any) {
          var image = new Image();
          image.onload = function (imageEvent: any) {

            // Resize the image
            var canvas = document.createElement('canvas'),
              max_size = 544,// TODO : pull max size from a site config
              width = image.width,
              height = image.height;
            if (width > height) {
              if (width > max_size) {
                height *= max_size / width;
                width = max_size;
              }
            } else {
              if (height > max_size) {
                width *= max_size / height;
                height = max_size;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            var dataUrl = canvas.toDataURL('image/jpeg');
            var dataURL = dataUrl;


            /* Utility function to convert a canvas to a BLOB */
            var BASE64_MARKER = ';base64,';
            if (dataURL.indexOf(BASE64_MARKER) == -1) {
              var parts = dataURL.split(',');
              var contentType = parts[0].split(':')[1];
              var raw = parts[1];

              return new Blob([raw], { type: contentType });
            }

            var parts = dataURL.split(BASE64_MARKER);
            var contentType = parts[0].split(':')[1];
            var raw = window.atob(parts[1]);
            var rawLength = raw.length;

            var uInt8Array = new Uint8Array(rawLength);

            for (var i = 0; i < rawLength; ++i) {
              uInt8Array[i] = raw.charCodeAt(i);
            }

            var resizedImage = new Blob([uInt8Array], { type: contentType });
            /* End Utility function to convert a canvas to a BLOB    */

            $.event.trigger({
              type: "imageResized",
              blob: resizedImage,
              url: dataUrl
            });
          }
          $("#profileImage").attr("src", readerEvent.target.result);

          // $("#imageForm").reset();

        }
        reader.readAsDataURL(file);

      }
      /*shrink*/


      this.spinner.show();
      this.store.uploadImageForProfile(event).subscribe((res: any) => {
        this.spinner.hide();
        if (res) {
          this.toastr.success(res.message);
          this.profileArr.profile_image = res.media;
          this.profileArr.imageMediaId = res.media_id;
        } else {
          this.toastr.success(res.message);
          return;
        }
      },
        (err: any) => {
          this.spinner.hide();
          if (!this.common.checkValidAuthResponseCode(err)) {
            return;
          }
          if (err.error.text) {
            this.toastr.success(err.error.text);
          } else {
            this.toastr.success(err.error.message);
          }
        }
      );
    }

  }


  selectedCountryCode = {
    id: 101,
    name: "India",
    code: 91
  };
  countryCodeChange(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    this.selectedCountryCode = event.value;
    this.address.country_id = event.value.id;
  }
  selectedCountry = {
    id: 101,
    name: "India",
    code: 91
  };
  countryChange(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    this.selectedCountry = event.value;
    this.getStatesList(event.value.id);
  }
  selectedState: any;
  stateChange(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    this.selectedState = event.value;
    this.getCitiesList(event.value.id);
  }
  selectedCity: any;
  cityChange(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    this.selectedCity = event.value;
  }
  public submitAddTeamMember(form: any) {
    if (!form.value) {
      this.toastr.warning("Invalid details!");
      return;
    }

    if (form.value.panNumber === "" && form.value.aadharNumber === "" && form.value.gstNumber === "") {
      this.toastr.warning("Enter atleast PAN or Aadhar or GST");
      return;
    }

    if (this.selectedCountry.id === 101) {
      if (form.value.pincode.length !== 6) {
        this.toastr.warning("Invalid pincode!");
        return;
      }
    }

    const data = {
      "company_id": this.companyId,
      "media_id": form.value.image,
      "name": form.value.company_name,
      "birthdate": form.value.birthdate,
      "phone_number": form.value.phone_number,
      "email": form.value.email,
      "role_id": 6,
      "gst_number": form.value.gstNumber,
      "aadhar_number": form.value.aadharNumber,
      "pan_number": form.value.panNumber,
      "address_line1": form.value.address1,
      "address_line2": form.value.address2,
      "contact_person_name": form.value.contact_person_name,
      "company_name": form.value.company_name,
      "country_id": this.selectedCountry.id,
      "state_id": this.selectedState.id,
      "city_id": this.selectedCity.id,
      "pincode": form.value.pincode,
    };


    this.spinner.show();
    this.auth.addTeamMember(data).subscribe((res: any) => {
      this.spinner.hide();
      //console.log(res);

      if (!res) {
        this.toastr.error("Invalid authentication!");
        return;
      }
      if (res.status === 200) {
        this.toastr.success(res.message);
        form.reset();
        this.dismiss();
        if (res.data) {
          this.profileArr = res.data;
        }
        //  this.router.navigate(['/personalDetails2']);
      } else {
        this.toastr.error(res.message);
      }
    },
      (err: any) => {
        this.spinner.hide();
        if (!this.common.checkValidAuthResponseCode(err)) {
          return;
        }
        if (err.error.text) {
          this.toastr.warning(err.error.text);
        } else {
          this.toastr.warning(err.error.message);
        }
      }
    );


  }


}
