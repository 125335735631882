import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ToastrModule } from 'ngx-toastr';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { NgxSpinnerModule } from "ngx-spinner";
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// import { ProductDetailsComponent } from './pages/product-details/product-details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InternetComponent } from './pages/internet/internet.component';
import { Device } from '@ionic-native/device/ngx';
import { FCM } from '@ionic-native/fcm/ngx';
import { Network } from '@ionic-native/network/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { AddressFormComponent } from './components/forms/address-form/address-form.component';
import { DeliveryAddressFormComponent } from './components/forms/delivery-address-form/delivery-address-form.component';
import { AddTeamComponent } from './pages/account/components/add-team/add-team.component';
// import { Smartlook } from '@ionic-native/smartlook/ngx';

import { DisplayProductDetailsComponent } from './pages/display-product-details/display-product-details.component';
import { RedeemPointsComponent } from './components/redeem-points/redeem-points.component';
import { RedeemPointsOTPComponent } from './components/redeem-points-otp/redeem-points-otp.component';
// import { SmsRetriever } from '@ionic-native/sms-retriever/ngx';
import { CurrencySymbolPipe } from './currency-symbol.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FilterPipe } from './filter.pipe';
import { IonicImageLoaderModule } from 'ionic-image-loader-v5';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
// import { TimeAgoPipe } from 'time-ago-pipe';
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";
import { IonicSelectableModule } from 'ionic-selectable';
import { Crop } from '@ionic-native/crop/ngx';
import { File } from '@ionic-native/file/ngx';
import { Camera } from '@ionic-native/Camera/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Uid } from '@ionic-native/uid/ngx';
import { Daterangepicker } from 'ng2-daterangepicker';
import { HttpConfigInterceptor } from './httpConfig.interceptor';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { 
  HTTP_INTERCEPTORS 
} from '@angular/common/http';
@NgModule({
  declarations: [AppComponent,
    // ProductDetailsComponent,
    InternetComponent,
    AddressFormComponent,
    DeliveryAddressFormComponent,
    AddTeamComponent,
    FilterPipe,
    CurrencySymbolPipe,
    RedeemPointsOTPComponent,
    RedeemPointsComponent
  ],
  entryComponents: [
    // ProductDetailsComponent,
    InternetComponent
  ],
  imports: [
    Daterangepicker,
    IonicSelectableModule,
    IonicImageLoaderModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    Ng2SearchPipeModule,
    NgxSkeletonLoaderModule.forRoot({ animation: "pulse" }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      timeOut: 5000,
      maxOpened: 1,
      preventDuplicates: true
    }),
    MatExpansionModule,
    ReactiveFormsModule,
    FormsModule,
    IonicStorageModule.forRoot()
  ],
  providers: [
    // Smartlook,
    SocialSharing,
    WebView,
    Crop,
    Camera,
    File,
    // SmsRetriever,
    Keyboard,
    StatusBar,
    Device,
    Network,
    UniqueDeviceID,
    InternetComponent,
    FCM,
    BarcodeScanner,
    // SplashScreen,
    Uid,
    AndroidPermissions,
    Device,
    InAppBrowser,
    CallNumber,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    }
  ],

  exports: [CurrencySymbolPipe, FilterPipe],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }

