import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  pagesList = [];
  constructor(private auth: AuthService) {
    console.log(this.auth.user$);


  //  this.pagesList = this.pagesList1;
  }


}
