import { HttpHeaders } from '@angular/common/http';

//prod
// export const environment = {
//   website: 'https://vriddhy.com/',
//   production: false,
//   rootCloudUrl: 'https://app.vriddhy.com/',
//   storeId: '6ca1ae68-320b-43f2-ad55-47fce05f9efa',
//   globalPushNotificationsTopic: 'vridhhy_prod'
// };

// staging
export const environment = {
  website: 'https://vriddhy.com/',
  production: false,
  rootCloudUrl: 'https://staging.tzutec.com/',
  storeId: '6ca1ae68-320b-43f2-ad55-47fce05f9efa',
  globalPushNotificationsTopic: 'vridhhy_stag'
};

export const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
