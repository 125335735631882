import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, httpOptions } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from './common.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    cheatMenu: false;
    user$: any = [];
    appPages$: any = [];
    constructor(private http: HttpClient, public common: CommonService, private _location: Location, public router: Router) {
    }

    goBack() {
        this._location.back();
    }
    guestWholesalerPagesList = [
        {
            title: 'Home (Guest-Wholesaler)',
            url: '/guest/home',
            icon: 'body-outline'
        },
        {
            type: 'hasSubMenus',
            title: 'Categories',
            icon: 'grid-outline',
            children: []
        },
        {
            title: 'New Arrivals',
            url: '/new_arrival',
            icon: 'ribbon-outline'
        },
        {
            title: 'Hot Selling',
            url: '/hot_selling',
            icon: 'flame-outline'
        },
        {
            title: 'Festive Collection',
            url: '/festive_collection',
            icon: 'gift-outline'
        },
        {
            type: 'hasSubMenus',
            title: 'Queries',
            icon: 'call-outline',
            children: [
                {
                    title: 'Raise A Query',
                    url: '/queries'
                },
                {
                    title: 'Query List',
                    url: '/queryList'
                },
            ]
        },
        {
            title: 'Help',
            url: '/help',
            icon: 'information-circle-outline'
        }, {
            title: 'About Us',
            url: '/about-us',
            icon: 'people-circle-outline'
        },
        {
            title: 'Invite',
            url: '/invite',
            icon: 'share'
        },
        {
            title: 'My Profile',
            url: '/profile',
            icon: 'person-outline'
        },
        {
            title: 'Upload Documents',
            url: '/personalDetails',
            icon: 'thumbs-up-outline'
        }
    ];

    // ,{
    //     title: 'Order Returns',
    //     url: '/tabs/orders/order-returns'
    // }
    masterWholesalerPagesList = [
        {
            title: 'Home (Master WS)',
            url: '/tabs/tab1',
            icon: 'body-outline'
        },
        {
            type: 'hasSubMenus',
            title: 'Categories',
            icon: 'grid-outline',
            children: []
        },
        {
            title: 'New Arrivals',
            url: '/new_arrival',
            icon: 'ribbon-outline'
        },

        {
            title: 'Hot Selling',
            url: '/hot_selling',
            icon: 'flame-outline'
        }
        ,
        {
            title: 'Festive Collection',
            url: '/festive_collection',
            icon: 'gift-outline'
        },
        // {
        //     title: 'Loyalty Points',
        //     url: '/loyalty-points',
        //     icon: 'checkmark-outline'
        // },
        {
            type: 'hasSubMenus',
            title: 'Queries',
            icon: 'call-outline',
            children: [
                {
                    title: 'Raise A Query',
                    url: '/queries'
                },
                {
                    title: 'Query List',
                    url: '/queryList'
                },
            ]
        },

        {
            type: 'hasSubMenus',
            title: 'My Accounts',
            icon: 'checkmark-outline',
            children: [
                {
                    title: 'My Ledger',
                    url: '/tabs/accounts',
                },
                {

                    title: 'My Payments',
                    url: '/tabs/offline-payments',
                },
                {
                    title: 'My Invoices',
                    url: '/tabs/invoices',
                }

            ]
        },
        {
            type: 'hasSubMenus',
            title: 'My Orders',
            icon: 'cube-outline',
            children: [
                {
                    title: 'Orders List',
                    url: '/tabs/orders'
                },
                {
                    title: 'Approved Orders',
                    url: '/tabs/orders/accepted-orders'
                },
                {
                    title: 'Order Returns',
                    url: '/tabs/orders/order-returns-initiate'
                }
            ]
        },


        // {
        //     title: 'My Accounts',
        //     url: '/tabs/accounts',
        //     icon: 'checkmark-outline'
        // },
        // {
        //     title: 'My Offline Payments',
        //     url: '/tabs/offline-payments',
        //     icon: 'wallet'
        // },

        // {
        //     type: 'hasSubMenus',
        //     title: 'My Orders',
        //     icon: 'cube-outline',
        //     children: [
        //         {
        //             title: 'Orders List',
        //             url: '/tabs/orders'
        //         },
        //         {
        //             title: 'Approved Orders',
        //             url: '/tabs/orders/accepted-orders'
        //         },
        //         {
        //             title: 'Order Returns',
        //             url: '/tabs/orders/order-returns-initiate'
        //         }
        //     ]
        // },
        // {
        //     title: 'My Invoices',
        //     url: '/tabs/invoices',
        //     icon: 'documents-outline'
        // },
        {
            title: 'Help',
            url: '/help',
            icon: 'information-circle-outline'
        }, {
            title: 'About Us',
            url: '/about-us',
            icon: 'people-circle-outline'
        },
        {
            title: 'Invite',
            url: '/invite',
            icon: 'share'
        },
        {
            title: 'My Profile',
            url: '/profile',
            icon: 'person-outline'
        }
    ];


    wholesalerPagesList = [
        {
            title: 'Home (Wholesaler)',
            url: '/tabs/tab1',
            icon: 'body-outline'
        },
        {
            type: 'hasSubMenus',
            title: 'Categories',
            icon: 'grid-outline',
            children: []
        },
        {
            title: 'New Arrivals',
            url: '/new_arrival',
            icon: 'ribbon-outline'
        },

        {
            title: 'Hot Selling',
            url: '/hot_selling',
            icon: 'flame-outline'
        }
        ,
        {
            title: 'Festive Collection',
            url: '/festive_collection',
            icon: 'gift-outline'
        },
        {
            type: 'hasSubMenus',
            title: 'Queries',
            icon: 'call-outline',
            children: [
                {
                    title: 'Raise A Query',
                    url: '/queries'
                },
                {
                    title: 'Query List',
                    url: '/queryList'
                },
            ]
        },


        {
            title: 'My Accounts',
            url: '/tabs/accounts',
            icon: 'checkmark-outline'
        },



        {
            type: 'hasSubMenus',
            title: 'My Orders',
            icon: 'cube-outline',
            children: [
                {
                    title: 'Orders List',
                    url: '/tabs/orders'
                }
            ]
        },
        {
            title: 'My Invoices',
            url: '/tabs/invoices',
            icon: 'documents-outline'
        },
        {
            title: 'About Us',
            url: '/about-us',
            icon: 'people-circle-outline'
        },


        {
            title: 'Help',
            url: '/help',
            icon: 'information-circle-outline'
        },
        {
            title: 'Invite',
            url: '/invite',
            icon: 'share'
        },
        {
            title: 'My Profile',
            url: '/profile',
            icon: 'person-outline'
        }
    ];

    subWholesalerPagesList = [
        {
            title: 'Home (Sub-Wholesaler)',
            url: '/tabs/tab1',
            icon: 'body-outline'
        },
        {
            type: 'hasSubMenus',
            title: 'Categories',
            icon: 'grid-outline',
            children: []
        },
        {
            title: 'New Arrivals',
            url: '/new_arrival',
            icon: 'ribbon-outline'
        },
        {
            title: 'Hot Selling',
            url: '/hot_selling',
            icon: 'flame-outline'
        }
        ,
        {
            title: 'Festive Collection',
            url: '/festive_collection',
            icon: 'gift-outline'
        },
        {
            type: 'hasSubMenus',
            title: 'Queries',
            icon: 'call-outline',
            children: [
                {
                    title: 'Raise A Query',
                    url: '/queries'
                },
                {
                    title: 'Query List',
                    url: '/queryList'
                },
            ]
        },

        {
            title: 'My Accounts',
            url: '/tabs/accounts',
            icon: 'checkmark-outline'
        },




        {
            type: 'hasSubMenus',
            title: 'My Orders',
            icon: 'cube-outline',
            children: [
                {
                    title: 'Orders List',
                    url: '/tabs/orders'
                }
            ]
        }, {
            title: 'My Invoices',
            url: '/tabs/invoices',
            icon: 'documents-outline'
        },

        {
            title: 'About Us',
            url: '/about-us',
            icon: 'people-circle-outline'
        },

        {
            title: 'Help',
            url: '/help',
            icon: 'information-circle-outline'
        },
        {
            title: 'Invite',
            url: '/invite',
            icon: 'share'
        },
        {
            title: 'My Profile',
            url: '/profile',
            icon: 'person-outline'
        }
    ];
    agentPagesList = [
        {
            title: 'Home (Agent)',
            url: '/agents/home',
            icon: 'body-outline'
        },

        // {
        //     title: 'Loyalty Points',
        //     url: '/agents/loyalty-points',
        //     icon: 'checkmark-outline'
        // },
        {
            title: 'My Wholesaler',
            url: '/agents/agents',
            icon: 'people-circle-outline'
        },

        {
            title: 'My Sales Report',
            url: '/agents/invoices',
            icon: 'people-circle-outline'
        },
        {
            title: 'My Offline Payments',
            url: '/agents/offline-payments',
            icon: 'wallet'
        },

        {
            type: 'hasSubMenus',
            title: 'My Orders',
            icon: 'cube-outline',
            children: [
                {
                    title: 'Orders List',
                    url: '/agents/orders'
                }
            ]
        },


        {
            title: 'Place order (Wholesaler)',
            url: '/agents/place-orders',
            icon: 'people-circle-outline'
        },


        {
            type: 'hasSubMenus',
            title: 'Queries',
            icon: 'call-outline',
            children: [
                {
                    title: 'Raise A Query',
                    url: '/queries'
                },
                {
                    title: 'Query List',
                    url: '/queryList'
                },
            ]
        },
        {
            title: 'About Us',
            url: '/about-us',
            icon: 'people-circle-outline'
        },
        {
            title: 'Help',
            url: '/help',
            icon: 'information-circle-outline'
        },
        {
            title: 'Invite',
            url: '/invite',
            icon: 'share'
        },
        {
            title: 'Refer Wholesaler',
            url: '/agents/referral',
            icon: 'star-sharp'
        },
        {
            title: 'My Profile',
            url: '/profile',
            icon: 'person-outline'
        }
    ];

    is_not_authorized_agentPagesList = [
        {
            title: 'Home (Agent)',
            url: '/agents/home',
            icon: 'body-outline'
        },

        // {
        //     title: 'Loyalty Points',
        //     url: '/agents/loyalty-points',
        //     icon: 'checkmark-outline'
        // },
        {
            title: 'My Wholesaler',
            url: '/agents/agents',
            icon: 'people-circle-outline'
        },

        {
            title: 'My Sales Report',
            url: '/agents/invoices',
            icon: 'people-circle-outline'
        },
        {
            title: 'My Offline Payments',
            url: '/agents/offline-payments',
            icon: 'wallet'
        },
        {
            type: 'hasSubMenus',
            title: 'My Orders',
            icon: 'cube-outline',
            children: [
                {
                    title: 'Orders List',
                    url: '/agents/orders'
                }
            ]
        },
        {
            type: 'hasSubMenus',
            title: 'Queries',
            icon: 'call-outline',
            children: [
                {
                    title: 'Raise A Query',
                    url: '/queries'
                },
                {
                    title: 'Query List',
                    url: '/queryList'
                },
            ]
        },
        {
            title: 'Help',
            url: '/help',
            icon: 'information-circle-outline'
        },
        {
            title: 'Invite',
            url: '/invite',
            icon: 'share'
        },
        {
            title: 'Refer Wholesaler',
            url: '/agents/referral',
            icon: 'star-sharp'
        },
        {
            title: 'My Profile',
            url: '/profile',
            icon: 'person-outline'
        }
    ];

    subAgentPagesList = [
        {
            title: 'Home (Sub-Agent)',
            url: '/agents/home',
            icon: 'body-outline'
        },


        {
            title: 'My Wholesaler',
            url: '/agents/agents',
            icon: 'people-circle-outline'
        },

        {
            title: 'My Sales Report',
            url: '/agents/invoices',
            icon: 'people-circle-outline'
        },
        {
            title: 'My Offline Payments',
            url: '/agents/offline-payments',
            icon: 'wallet'
        },
        {
            type: 'hasSubMenus',
            title: 'My Orders',
            icon: 'cube-outline',
            children: [
                {
                    title: 'Orders List',
                    url: '/agents/orders'
                }
            ]
        },


        {
            title: 'Place order (Wholesaler)',
            url: '/agents/place-orders',
            icon: 'people-circle-outline'
        },

        {
            type: 'hasSubMenus',
            title: 'Queries',
            icon: 'call-outline',
            children: [
                {
                    title: 'Raise A Query',
                    url: '/queries'
                },
                {
                    title: 'Query List',
                    url: '/queryList'
                },
            ]
        },
        {
            title: 'About Us',
            url: '/about-us',
            icon: 'people-circle-outline'
        },

        {
            title: 'Help',
            url: '/help',
            icon: 'information-circle-outline'
        },
        {
            title: 'Invite',
            url: '/invite',
            icon: 'share'
        },
        {
            title: 'Refer Wholesaler',
            url: '/agents/referral',
            icon: 'star-sharp'
        },
        {
            title: 'My Profile',
            url: '/profile',
            icon: 'person-outline'
        }
    ];

    is_not_authorized_subagentPagesList = [
        {
            title: 'Home (Sub-Agent)',
            url: '/agents/home',
            icon: 'body-outline'
        },


        {
            title: 'My Wholesaler',
            url: '/agents/agents',
            icon: 'people-circle-outline'
        },

        {
            title: 'My Sales Report',
            url: '/agents/invoices',
            icon: 'people-circle-outline'
        },
        {
            title: 'My Offline Payments',
            url: '/agents/offline-payments',
            icon: 'wallet'
        },
        {
            type: 'hasSubMenus',
            title: 'My Orders',
            icon: 'cube-outline',
            children: [
                {
                    title: 'Orders List',
                    url: '/agents/orders'
                }
            ]
        },

        {
            type: 'hasSubMenus',
            title: 'Queries',
            icon: 'call-outline',
            children: [
                {
                    title: 'Raise A Query',
                    url: '/queries'
                },
                {
                    title: 'Query List',
                    url: '/queryList'
                },
            ]
        },
        {
            title: 'Refer Wholesaler',
            url: '/agents/referral',
            icon: 'star-sharp'
        },
        {
            title: 'About Us',
            url: '/about-us',
            icon: 'people-circle-outline'
        },
        {
            title: 'Help',
            url: '/help',
            icon: 'information-circle-outline'
        },
        {
            title: 'Invite',
            url: '/invite',
            icon: 'share'
        },
        {
            title: 'My Profile',
            url: '/profile',
            icon: 'person-outline'
        }
    ];

    salesUserPagesList = [
        {
            title: 'Home (Sales-User)',
            url: '/crm/home',
            icon: 'body-outline'
        },

        {
            title: 'Place order (Wholesaler)',
            url: '/crm/place-orders',
            icon: 'people-circle-outline'
        },
        {
            title: 'My Wholesalers',
            url: '/crm/agents',
            icon: 'people-circle-outline'
        },
        {
            title: 'My Offline Payments',
            url: '/crm/offline-payments',
            icon: 'wallet'
        },


        {
            title: 'My Targets',
            url: '/crm/targets',
            icon: 'thumbs-up-outline'
        },
        {
            type: 'hasSubMenus',
            title: 'My Orders',
            icon: 'cube-outline',
            children: [
                {
                    title: 'Orders List',
                    url: '/crm/orders'
                }
            ]
        },
        {
            type: 'hasSubMenus',
            title: 'Queries',
            icon: 'call-outline',
            children: [
                {
                    title: 'Raise A Query',
                    url: '/queries'
                },
                {
                    title: 'Query List',
                    url: '/queryList'
                },
            ]
        },
        {
            title: 'About Us',
            url: '/about-us',
            icon: 'people-circle-outline'
        },
        {
            title: 'Help',
            url: '/help',
            icon: 'information-circle-outline'
        },
        {
            title: 'Invite',
            url: '/invite',
            icon: 'share'
        },
        // {
        //     title: 'Refer Wholesaler',
        //     url: '/crm/referral',
        //     icon: 'star-sharp'
        // },
        {
            title: 'My Profile',
            url: '/profile',
            icon: 'person-outline'
        }

    ];

    pagesList = [];


    setUser(res: any, username: any) {
        /* login auth guard data*/
        localStorage.setItem('currentUserProfile', JSON.stringify(res.data));
        localStorage.setItem('loginToken', res.token);
        localStorage.setItem('usernameOrEmail', username);
        localStorage.setItem('company_name', res.company_name);
        this.user$ = res.data;
        this.appPages$ = this.getPages(this.user$.role_id);
        /* login auth guard data*/
    }





    pagesList1 = [
        {
            title: 'Guest Homepage',
            url: '/guest/tab1',
            icon: 'body-outline'
        },
        {
            title: 'Agent Homepage',
            url: '/agents/tab1',
            icon: 'body-outline'
        },
        {
            type: 'directLink',
            title: 'Wholesaler Home',
            url: '/tabs/tab1',
            icon: 'home-outline'
        },
        {
            title: 'About Us',
            url: '/about-us',
            icon: 'people-circle-outline'
        },
        {
            title: 'Help',
            url: '/help',
            icon: 'information-circle-outline'
        },
        {
            title: 'Invite',
            url: '/invite',
            icon: 'share'
        },
        {
            type: 'hasSubMenus',
            title: 'Categories',
            // url: '/categories',
            icon: 'grid-outline',
            children: [
                {
                    title: 'Lehenga',
                    url: ''
                },
                {
                    title: 'Suits',
                    url: ''
                },
                {
                    title: 'Sarees',
                    url: ''
                },
                {
                    title: 'Dupattas',
                    url: ''
                }
            ]
        },
        // {
        //   title: 'Update Company Details',
        //   url: '/personalDetails',
        //   icon: 'thumbs-up-outline'
        // },
        {
            title: 'Recommended For You',
            url: '/tabs/tab1',
            icon: 'thumbs-up-outline'
        },
        {
            title: 'New Arrivals',
            url: '/new_arrival',
            icon: 'ribbon-outline'
        },
        {
            title: 'Hot Selling',
            url: '/hot_selling',
            icon: 'flame-outline'
        }
        ,
        {
            title: 'Festive Collection',
            url: '/festive_collection',
            icon: 'gift-outline'
        },
        // {
        //     title: 'Loyalty Points',
        //     url: '/accounts/loyalty-points',
        //     icon: 'checkmark-outline'
        // },
        {
            title: 'Accounts',
            url: '/accounts',
            icon: 'checkmark-outline'
        },
        {
            title: 'My Profile',
            url: '/profile',
            icon: 'person-outline'
        },
        {
            title: 'My Invoices',
            url: '/invoices',
            icon: 'documents-outline'
        },
        {
            type: 'hasSubMenus',
            title: 'My Orders',
            icon: 'cube-outline',
            children: [
                {
                    title: 'Orders List',
                    url: '/orders'
                },
                {
                    title: 'Approved Orders',
                    url: '/orders/accepted-orders'
                }, {
                    title: 'Order Returns',
                    url: '/orders/order-returns'
                }
            ]
        },
        {
            type: 'hasSubMenus',
            title: 'Queries',
            icon: 'call-outline',
            children: [
                {
                    title: 'Raise A Query',
                    url: '/queries'
                },
                {
                    title: 'Query List',
                    url: '/queryList'
                },
            ]
        },
        // {
        //   title: 'Shop',
        //   url: '/products',
        //   icon: 'basket-outline'
        // },

        // {
        //   title: 'Checkout',
        //   url: '/checkout',
        //   icon: 'checkbox-outline'
        // },
        // {
        //   title: 'Search',
        //   url: '/search',
        //   icon: 'search-outline'
        // },
        // {
        //   title: 'Deals',
        //   url: '/tab2',
        //   icon: 'gift'
        // },
        // {
        //   title: 'Wishlist',
        //   url: '/tab3',
        //   icon: 'heart'
        // },
        // {
        //   title: 'Notification',
        //   url: '/tab4',
        //   icon: 'notifications-outline'
        // },
        // {
        //   title: 'Orders',
        //   url: '/orders',
        //   icon: 'checkmark-circle-outline'
        // }
    ];

    getPages(roleId) {
        this.getCategoriesListForSidebar();

        switch ((+roleId)) {
            case 1:
                this.pagesList = this.pagesList1;
                break;
            case 2:
                this.pagesList = this.pagesList1;
                break;
            case 3:
                this.pagesList = this.guestWholesalerPagesList;
                break;
            case 4:
                this.pagesList = this.masterWholesalerPagesList;
                break;
            case 5:
                this.pagesList = this.subWholesalerPagesList;
                break;
            case 6:
                this.pagesList = this.wholesalerPagesList;
                break;
            case 7:
                if (this.user$.is_authorized === 'Yes') {
                    this.pagesList = this.agentPagesList;
                } else {
                    this.pagesList = this.is_not_authorized_agentPagesList;
                }
                break;
            case 8:
                if (this.user$.is_authorized === 'Yes') {
                    this.pagesList = this.subAgentPagesList;
                } else {
                    this.pagesList = this.is_not_authorized_agentPagesList;
                }
                break;
            case 10:
                this.pagesList = this.salesUserPagesList;
                break;
            case 11:
                this.pagesList = this.salesUserPagesList;
                break;
            default:
                this.pagesList = this.pagesList1;
                break;
        }

        if (this.cheatMenu) {
            this.pagesList = this.pagesList1;
        }
        return this.pagesList
    }










    getUrl(url: any, conf: any) {
        let link: any;
        link = url;
        switch (url) {
            case "/privacy":
                link = conf.privacy_policy;
                break;
            case "/terms":
                link = conf.terms_and_condition;
                break;
            case "/about-us":
                link = conf.about_us;
                break;
            default:
                link = url;
                break;
        }
        return link;
    }




    getHelps() {
        let roleId = this.user$.role_id;
        let helpItems = [];
        switch ((+roleId)) {
            case 1:
                helpItems = [
                    {
                        title: "Raise A Return Request?",
                        url: "/order-returns",
                        btnText: "Click Here"
                    },
                    {
                        title: "Raise A Query?",
                        url: "/queries",
                        btnText: "Click Here"
                    },
                    {
                        title: "Privacy Policy",
                        url: "/privacy",
                        btnText: "Click Here"
                    },
                    {
                        title: "Terms & Conditions",
                        url: "/terms",
                        btnText: "Click Here"
                    }
                ];
                break;
            case 2:
                helpItems = [
                    {
                        title: "Raise A Return Request?",
                        url: "/order-returns",
                        btnText: "Click Here"
                    },
                    {
                        title: "Raise A Query?",
                        url: "/queries",
                        btnText: "Click Here"
                    },
                    {
                        title: "Privacy Policy",
                        url: "/privacy",
                        btnText: "Click Here"
                    },
                    {
                        title: "Terms & Conditions",
                        url: "/terms",
                        btnText: "Click Here"
                    }
                ];
                break;
            case 3:
                helpItems = [

                    {
                        title: "Raise A Query?",
                        url: "/queries",
                        btnText: "Click Here"
                    },
                    {
                        title: "Privacy Policy",
                        url: "/privacy",
                        btnText: "Click Here"
                    },
                    {
                        title: "Terms & Conditions",
                        url: "/terms",
                        btnText: "Click Here"
                    }
                ];
                break;
            case 4:
                helpItems = [
                    {
                        title: "Raise A Return Request?",
                        url: "/order-returns",
                        btnText: "Click Here"
                    },
                    {
                        title: "Raise A Query?",
                        url: "/queries",
                        btnText: "Click Here"
                    },
                    {
                        title: "Privacy Policy",
                        url: "/privacy",
                        btnText: "Click Here"
                    },
                    {
                        title: "Terms & Conditions",
                        url: "/terms",
                        btnText: "Click Here"
                    }
                ];
                break;
            case 5:
                helpItems = [
                    {
                        title: "Raise A Return Request?",
                        url: "/order-returns",
                        btnText: "Click Here"
                    },
                    {
                        title: "Raise A Query?",
                        url: "/queries",
                        btnText: "Click Here"
                    },
                    {
                        title: "Privacy Policy",
                        url: "/privacy",
                        btnText: "Click Here"
                    },
                    {
                        title: "Terms & Conditions",
                        url: "/terms",
                        btnText: "Click Here"
                    }
                ];
                break;
            case 6:
                helpItems = [
                    {
                        title: "Raise A Return Request?",
                        url: "/order-returns",
                        btnText: "Click Here"
                    },
                    {
                        title: "Raise A Query?",
                        url: "/queries",
                        btnText: "Click Here"
                    },
                    {
                        title: "Privacy Policy",
                        url: "/privacy",
                        btnText: "Click Here"
                    },
                    {
                        title: "Terms & Conditions",
                        url: "/terms",
                        btnText: "Click Here"
                    }
                ];
                break;
            case 7:
                helpItems = [

                    {
                        title: "Raise A Query?",
                        url: "/queries",
                        btnText: "Click Here"
                    },
                    {
                        title: "Privacy Policy",
                        url: "/privacy",
                        btnText: "Click Here"
                    },
                    {
                        title: "Terms & Conditions",
                        url: "/terms",
                        btnText: "Click Here"
                    }
                ];
                break;
            case 8:
                helpItems = [

                    {
                        title: "Raise A Query?",
                        url: "/queries",
                        btnText: "Click Here"
                    },
                    {
                        title: "Privacy Policy",
                        url: "/privacy",
                        btnText: "Click Here"
                    },
                    {
                        title: "Terms & Conditions",
                        url: "/terms",
                        btnText: "Click Here"
                    }
                ];
                break;
            case 10:
                helpItems = [

                    {
                        title: "Raise A Query?",
                        url: "/queries",
                        btnText: "Click Here"
                    },
                    {
                        title: "Privacy Policy",
                        url: "/privacy",
                        btnText: "Click Here"
                    },
                    {
                        title: "Terms & Conditions",
                        url: "/terms",
                        btnText: "Click Here"
                    }
                ];
                break;
            case 11:
                helpItems = [

                    {
                        title: "Raise A Query?",
                        url: "/queries",
                        btnText: "Click Here"
                    },
                    {
                        title: "Privacy Policy",
                        url: "/privacy",
                        btnText: "Click Here"
                    },
                    {
                        title: "Terms & Conditions",
                        url: "/terms",
                        btnText: "Click Here"
                    }
                ];
                break;
            default:
                helpItems = [
                    {
                        title: "Raise A Return Request?",
                        url: "/order-returns",
                        btnText: "Click Here"
                    },
                    {
                        title: "Raise A Query?",
                        url: "/queries",
                        btnText: "Click Here"
                    },
                    {
                        title: "Privacy Policy",
                        url: "/privacy",
                        btnText: "Click Here"
                    },
                    {
                        title: "Terms & Conditions",
                        url: "/terms",
                        btnText: "Click Here"
                    }
                ];
                break;
        }

        return helpItems;
    }

    public camelCase(str: any) {
        return str.toLowerCase()
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    categoriesList = [];
    public getCategoriesListForSidebar() {
        if (!localStorage.getItem('loginToken')) {
            return;
        }

        const data = {
        };

        //this.spinner.show();
        this.getCategoriesList(data).subscribe((res: any) => {
            //  this.spinner.hide();
            //console.log(res);
            if (!res) {
                //   this.toastr.error("Invalid authentication!");
                return;
            }
            if (res.status === 200) {
                this.categoriesList = [];
                Object.keys(res.data).forEach((key) => {
                    var item = {
                        title: this.camelCase(res.data[key].name),
                        // url: '/products/' + res.data[key].id,
                        url: '/departments/' + res.data[key].id,
                    }

                    this.categoriesList.push(item);
                });
                if (this.appPages$[1].title === 'Categories') {
                    this.appPages$[1].children = this.categoriesList;
                }

            } else {
                // this.toastr.error(res.message);
            }
        },
            (err: any) => {
                if (!this.common.checkValidAuthResponseCode(err)) {
                    return;
                }
                //  this.spinner.hide();
                console.log(err);
            }
        );


    }
    viewProfile(data: any): Observable<any> {
        let requestData = JSON.stringify({
        });
        let url = environment.rootCloudUrl + "api/my-profile";
        if (this.user$.role_id === 7) {
            url = environment.rootCloudUrl + "api/agent/my-profile";
        } else if (this.user$.role_id === 8) {
            url = environment.rootCloudUrl + "api/agent/my-profile";
        } else if (this.user$.role_id === 11) {
            if (data.from_date && data.to_date)
                url = environment.rootCloudUrl + "api/sale-user/profile?from_date=" + data.from_date + "&to_date=" + data.to_date;
            else
                url = environment.rootCloudUrl + "api/sale-user/profile";

        } else {
            url = environment.rootCloudUrl + "api/my-profile";
        }

        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    signout(): Observable<any> {
        const url = environment.rootCloudUrl + "api/logout";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }



    checkPendingApproval(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/company/check-pending-approval";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    getCompanyDetails(data: any): Observable<any> {

        if (this.user$.role_id === 7 || this.user$.role_id === 8) {
            data.wholesaler_user_id = localStorage.getItem('wholesaler_user_id');
            // data.customer_user_id = localStorage.getItem('wholesaler_user_id');
        } else {
            data.wholesaler_user_id = this.user$.id;
            //data.customer_user_id = this.user$.id;
        }

        data.company_id = data.company_id;
        data.step = data.step;
        // let requestData = JSON.stringify({
        //     "company_id": data.company_id,
        //     "step": data.step
        // });

        const url = environment.rootCloudUrl + "api/get-company-details";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }
    getCountriesList(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/country-list";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }
    getStatesList(country: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/state-list?country_id=" + country;
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    getCitiesList(state: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/city-list?state_id=" + state;
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }
    getStateCityCountriesList(): Observable<any> {
        const url = environment.rootCloudUrl + "api/get-city-state";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }
    getAddressTypeList(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/address-type-list";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    getCompanyAddressList(data: any): Observable<any> {
        let url = environment.rootCloudUrl + "api/get-company-addresses?company_id=" + data.id;

        if (this.user$.role_id === 7 || this.user$.role_id === 8) {
            //data.wholesaler_user_id = localStorage.getItem('wholesaler_user_id');
            // data.customer_user_id = localStorage.getItem('wholesaler_user_id');
            //url = environment.rootCloudUrl + "api/get-company-addresses?company_id=" + data.id + "&wholesaler_user_id=" + data.wholesaler_user_id;

        }
        if (this.user$.role_id === 7 || this.user$.role_id === 8 || this.user$.role_id === 11) {
            url = environment.rootCloudUrl + "api/get-company-addresses?company_id=" + data.id+"&wholesaler_user_id="+localStorage.getItem('wholesaler_user_id');
        } 

        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    getWholesalerAddressList(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/get-subwholesaler-address";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    getCompanyList(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/company-list";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    setCompanyDetails(data: any): Observable<any> {

        if (this.user$.role_id === 3) {
            data.company_setup_type = "registraion";
        } else {
            data.company_setup_type = "add_company";
        }

        let requestData = JSON.stringify(data);

        const url = environment.rootCloudUrl + 'api/set-full-company-details';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }
    setCompanyDetailsLazy(data: any): Observable<any> {

        if (this.user$.role_id === 3) {
            data.company_setup_type = "registraion";
        } else {
            data.company_setup_type = "add_company";
        }

        let requestData = JSON.stringify(data);




        const url = environment.rootCloudUrl + 'api/set-company-details';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }
    setGSTAddress(data: any): Observable<any> {
        let requestData = JSON.stringify(data);
        const url = environment.rootCloudUrl + 'api/company-gst-verification';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }
    addDeliveryAddress(data: any): Observable<any> {
        let requestData = JSON.stringify(data);

        const url = environment.rootCloudUrl + 'api/add-delivery-address';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }


    placeOrder(data: any): Observable<any> {
        if (this.user$.role_id === 7 || this.user$.role_id === 8 || this.user$.role_id === 11) {
            data.wholesaler_user_id = localStorage.getItem('wholesaler_user_id');
            data.customer_user_id = localStorage.getItem('wholesaler_user_id');
        } else {
            data.wholesaler_user_id = this.user$.id;
            data.customer_user_id = this.user$.id;
        }

        let requestData = JSON.stringify(data);

        const url = environment.rootCloudUrl + 'api/place-order';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }

    payOffline(event: any): Observable<any> {
        let formData: FormData = new FormData();
        formData.append('amount', event.amount);
        formData.append('rgst_reference_number', event.rgst_reference_number);
        formData.append('date', event.date);
        // formData.append('offline_payment_amount', event.offline_payment_amount);
        formData.append('cheque_media_id', event.cheque_media_id);



        const url = environment.rootCloudUrl + 'api/make-offline-payment';

        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, formData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }

    addShippingAddress(data: any): Observable<any> {
        let requestData = JSON.stringify(data);

        const url = environment.rootCloudUrl + 'api/add-shipping-address';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }


    editPersonalDetails(data: any): Observable<any> {
        let requestData = JSON.stringify(data);
        const url = environment.rootCloudUrl + 'api/edit-profile';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }


    editCompanyDetails(data: any): Observable<any> {
        let requestData = JSON.stringify(data);
        const url = environment.rootCloudUrl + 'api/update-company-details';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }

    addTeamMember(data: any): Observable<any> {
        let requestData = JSON.stringify(data);
        const url = environment.rootCloudUrl + 'api/add-team-member';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }



    register(data: any): Observable<any> {
        let requestData = JSON.stringify({
            "desiredRole": "ROLE_USER",
            "deviceFingerprint": {
                "createdAt": 0,
                "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
                "jsonOtherInfo": "string",
                "user_id": 0
            },
            "storeId": localStorage.getItem('storeId'),
            "email": data.email,
            "merchant": data.merchantFlag,
            "password": data.password,
            "username": data.mobile
        });
        const url = environment.rootCloudUrl + 'auth/register';
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    verifyOtp(data: any): Observable<any> {
        let requestData = JSON.stringify({
            "username": data.username,
            "device_id": localStorage.getItem('deviceFingerPrint'),
            "otp": data.otp,
            'asked_security_question': "correct"
        });

        const url = environment.rootCloudUrl + 'api/verify-otp';
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }

    verifyRedeemPointsOtp(data: any): Observable<any> {
        let requestData = JSON.stringify({
            "otp": data.otp,
        });
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        const url = environment.rootCloudUrl + 'api/ncpl-verify-otp';
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }


    gotoHome() {
        let redirectUrl = "/";
        switch ((+this.user$.role_id)) {
            case 1://admin
                redirectUrl = '/guest/home';
                break;
            case 2://employee
                redirectUrl = '/guest/home';
                break;
            case 3://guest ws
                redirectUrl = '/guest/home';
                break;
            case 4://master ws
                redirectUrl = '/tabs/tab1';
                break;
            case 5://sub ws
                redirectUrl = '/tabs/tab1';
                break;
            case 6://ws
                redirectUrl = '/tabs/tab1';
                break;
            case 7://agent
                redirectUrl = '/agents/home';
                break;
            case 8://sub agent
                redirectUrl = '/agents/home';
                break;
            case 10://agent
                redirectUrl = '/crm/home';
                break;
            case 11://agent
                redirectUrl = '/crm/home';
                break;
            default://none
                redirectUrl = '/guest/home';
                break;
        }
        return redirectUrl = redirectUrl;
    }

    gotoOrders() {
        let redirectUrl = "/";
        switch ((+this.user$.role_id)) {
            case 1://admin
                // redirectUrl = '/orders';
                break;
            case 2://employee
                // redirectUrl = '/tabs/orders';
                break;
            case 3://guest ws
                // redirectUrl = '/tabs/orders';
                break;
            case 4://master ws
                redirectUrl = '/tabs/orders';
                break;
            case 5://sub ws
                redirectUrl = '/tabs/orders';
                break;
            case 6://ws
                redirectUrl = '/tabs/orders';
                break;
            case 7://agent
                redirectUrl = '/agents/orders';
                break;
            case 8://sub agent
                redirectUrl = '/agents/orders';
                break;
            case 10://agent
                redirectUrl = '/crm/orders';
                break;
            case 11://agent
                redirectUrl = '/crm/orders';
                break;
            default://none
                redirectUrl = '/tabs/orders';
                break;
        }
        return redirectUrl = redirectUrl;
    }


    getCategoriesList(data: any): Observable<any> {
        let requestData = JSON.stringify({
        });

        const url = environment.rootCloudUrl + "api/get-categories";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }
    getAllCategoriesList(data: any): Observable<any> {
        let requestData = JSON.stringify({
        });

        const url = environment.rootCloudUrl + "api/get-all-categories";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }
    geDepartmentsList(data: any): Observable<any> {
        let requestData = JSON.stringify({
            category_name: data.category_name
        });

        const url = environment.rootCloudUrl + "api/get-departments?division_id=" + data.category_name + "&page=" + data.page;
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    getProductsList(data: any): Observable<any> {
        let requestData = {
            category_id: data.category_id
        };

        const url = environment.rootCloudUrl + "api/get-products";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    getProductsWithFiltersList(data: any): Observable<any> {

        console.table(data);

        if (this.user$.role_id === 7 || this.user$.role_id === 8 || this.user$.role_id === 11) {
            data.wholesaler_user_id = localStorage.getItem('wholesaler_user_id') ? localStorage.getItem('wholesaler_user_id') : this.user$.id;
        }


        const url = environment.rootCloudUrl + "api/get-products";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken'), 'device_screen_width': localStorage.getItem('device_screen_width'), 'device_screen_height': localStorage.getItem('device_screen_height'), 'device_screen_type': localStorage.getItem('environment') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    getProductsWithFiltersListForScan(data: any): Observable<any> {

        console.table(data);

        const url = environment.rootCloudUrl + "api/bulk-cart-products";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    getLatestTrendProducts(): Observable<any> {
        let data = {};
        // console.table(data);
        const url = environment.rootCloudUrl + "api/get-latest-trends";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    getProductFiltersList(data: any, filter: any): Observable<any> {
        let requestData = {
            category_id: data.category_id
        };
        const url = environment.rootCloudUrl + "api/get-filter-options?category_id=" + data.category_id;
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, filter, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }



    getGuestProductDetails(data: any): Observable<any> {
        let requestData = {
            product_id: data.product_id
        };

        const url = environment.rootCloudUrl + "api/guest-product-detail";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        // const httpOptions = {
        //     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        // };
        return this.http.post(url, requestData)
            .pipe(
                map((response: Response) => response)
            )
    }

    getProductDetails(data: any): Observable<any> {
        let requestData = {
            product_id: data.product_id
        };

        const url = environment.rootCloudUrl + "api/get-product-detail";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    getProductCodeDetails(data: any): Observable<any> {
        let requestData = {
            product_code: data.product_code
        };

        const url = environment.rootCloudUrl + "api/get-product-detail";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    login(data: any): Observable<any> {
        let requestData = JSON.stringify({
            "password": data.password,
            "storeId": localStorage.getItem('storeId'),
            "usernameOrEmail": data.usernameOrEmail

        });
        const url = environment.rootCloudUrl + 'auth/login';
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }

    validateToken(data: any): Observable<any> {
        const url = environment.rootCloudUrl + 'api/get-current-session';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }


    passwordReset(data: any): Observable<any> {
        let requestData = JSON.stringify({
            "deviceFingerprint": {
                "createdAt": 0,
                "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
                "jsonOtherInfo": "string",
                "user_id": 0
            },
            "storeId": localStorage.getItem('storeId'),
            "password": data.password,
            "phoneNumber": data.mobile,
            "token": data.token,
        });
        const url = environment.rootCloudUrl + 'auth/passwordReset';
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }


    sendOtp(data: any): Observable<any> {
        let requestData = JSON.stringify({
            "username": data.username,
            "device_id": localStorage.getItem('deviceFingerPrint'),
            "environment": localStorage.getItem('environment'),
        });

        if (data.asked_security_question) {
            requestData = JSON.stringify({
                "username": data.username,
                "device_id": localStorage.getItem('deviceFingerPrint'),
                "environment": localStorage.getItem('environment'),
                'asked_security_question': data.asked_security_question
            });
        }

        const url = environment.rootCloudUrl + 'api/send-otp';
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }
    signinVerification(data: any): Observable<any> {
        let requestData = JSON.stringify({
            "username": data.username,
            "device_id": localStorage.getItem('deviceFingerPrint'),
            "environment": localStorage.getItem('environment'),
            "asked_security_question": data.asked_security_question
        });
        const url = environment.rootCloudUrl + 'api/check-security-answer';
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }


    changeDeviceId(data: any): Observable<any> {

        const url = environment.rootCloudUrl + 'api/change-deviceid';
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }

    resendOtp(data: any): Observable<any> {
        let requestData = JSON.stringify({
            "username": data.username,
            "device_id": localStorage.getItem('deviceFingerPrint'),
            "environment": localStorage.getItem('environment')
        });
        const url = environment.rootCloudUrl + 'api/send-otp';
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }
    resendRedeemPointsOtp(data: any): Observable<any> {
        let requestData = JSON.stringify({
            // "username": data.username,
            "device_id": localStorage.getItem('deviceFingerPrint'),
            "environment": localStorage.getItem('environment')
        });
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        const url = environment.rootCloudUrl + 'api/ncpl-get-otp';
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            );
    }

    // var t = localStorage.getItem("accessToken");
    // headers.append("Authorization", "Bearer " + t);
    // var body = JSON.stringify(user);

}
