import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from '../app/pages/about-us/about-us.component';

const routes: Routes = [
  { path: '', redirectTo: 'signin', pathMatch: 'full' },
  { path: 'onbroading', loadChildren: () => import('./pages/onboarding/onboarding.module').then(m => m.OnboardingModule) },
  { path: 'landing', loadChildren: () => import('./pages/auth/landing-page/landing-page.module').then(m => m.LandingPageModule) },
  { path: 'signup', loadChildren: () => import('./pages/auth/signup/signup.module').then(m => m.SignupModule) },
  { path: 'signin', loadChildren: () => import('./pages/auth/signin/signin.module').then(m => m.SigninModule) },

  { path: 'signin-verification/:username', loadChildren: () => import('./pages/auth/signin-verification/signin-verification.module').then(m => m.SigninVerificationModule) },
  { path: 'forget-password', loadChildren: () => import('./pages/auth/forget-password/forget-password.module').then(m => m.ForgetPasswordModule) },
  { path: 'verification/:username', loadChildren: () => import('./pages/auth/verification/verification.module').then(m => m.VerificationModule) },
  // { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule) },
  { path: 'custom-checkout', loadChildren: () => import('./pages/custom-checkout/custom-checkout.module').then(m => m.CustomCheckoutModule) },
  { path: 'pay-offline', loadChildren: () => import('./pages/payments/offline-payments/offline-payments.module').then(m => m.OfflinePaymentsModule) },
  { path: 'pay-now', loadChildren: () => import('./pages/payments/pay-now/pay-now.module').then(m => m.PayNowModule) },
  {
    path: 'notifications',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/notification/notification.module').then(m => m.NotificationModule)
      }
    ]
  },

  {
    path: 'terms',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/legal/terms/terms.module').then(m => m.TermsModule)
      }
    ]
  },
  {
    path: 'privacy',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/legal/privacy/privacy.module').then(m => m.PrivacyModule)
      }
    ]
  },
  {
    path: 'personalDetails',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/company-details/check-gst/check-gst.module').then(m => m.CheckGSTModule)
      }
    ]
  },
  {
    path: 'personalDetails2',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/company-details/check-gst/check-gst.module').then(m => m.CheckGSTModule)
      }
    ]
  },
  {
    path: 'personalDetails2/:id',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/company-details/stepform-data/stepform-data.module').then(m => m.StepFormDataModule)
      }
    ]
  },


  {
    path: 'profile',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/account/profile.module').then(m => m.ProfileModule)
      }
    ]
  },
  {
    path: 'qrCode',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/account/qr-code/qr-code.module').then(m => m.QrCodeModule)
      }
    ]
  },


  { path: '', loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule) },
  { path: 'queries', loadChildren: () => import('./pages/queries/raise-a-query/raise-a-query.module').then(m => m.RaiseAQueryModule) },
  { path: 'queries/:id', loadChildren: () => import('./pages/queries/raise-a-query/raise-a-query.module').then(m => m.RaiseAQueryModule) },
  
  { path: 'queryList', loadChildren: () => import('./pages/queries/query-list/query-list.module').then(m => m.QueryListModule) },
  {
    path: 'queryDetails/:id',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/queries/query-details/query-details.module').then(m => m.QueryDetailsModule)
      }
    ]
  },
  { path: 'about-us', loadChildren: () => import('./pages/about-us/about-us.module').then(m => m.AboutUsModule) },

  {
    path: 'help',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/help/help.module').then(m => m.HelpModule)
      }
    ]
  },
  {
    path: 'invite',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/invite/invite.module').then(m => m.InviteModule)
      }
    ]
  },
  { path: '', loadChildren: () => import('./pages/agent/agents/agents.module').then(m => m.AgentsPageModule) },
  { path: '', loadChildren: () => import('./pages/guest-wholesaler/guest-wholesalers/guest-wholesalers.module').then(m => m.GuestWholesalersModule) },
  { path: '', loadChildren: () => import('./pages/crm/crm/crm.module').then(m => m.CrmModule) },
  {
    path: 'loyalty-points',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/accounts/loyalty-points/loyalty-points.module').then(m => m.LoyaltyPointsModule)
      }
    ]
  },

  {
    path: 'categories',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/category/category.module').then(m => m.CategoryModule)
      }
    ]
  },
  {
    path: 'departments/:id',
    children: [
      {
        path: '',
        loadChildren: () =>
        import('./pages/department/department.module').then(m => m.DepartmentModule)
      }
    ]
  },
  {
    path: 'search',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/search/search.module').then(m => m.SearchModule)
      }
    ]
  },
  {
    path: 'fast-search',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/fast-search/fast-search.module').then(m => m.FastSearchModule)
      }
    ]
  },
  {
    path: 'search/:wholesaler_user_id',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/search/search.module').then(m => m.SearchModule)
      }
    ]
  },
  {
    path: 'search/:wholesaler_user_id/:search_key',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/search/search.module').then(m => m.SearchModule)
      }
    ]
  },
  {
    path: 'search/:wholesaler_user_id/:search_key/:random',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/search/search.module').then(m => m.SearchModule)
      }
    ]
  },
  {
    path: 'products',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/products/products.module').then(m => m.ProductsModule)
      }
    ]
  }

  ,
  {
    path: 'products/:id',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/products/products.module').then(m => m.ProductsModule)
      }
    ]
  }

  ,
  {
    path: 'products/:id/:wholesaler_user_id',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/products/products.module').then(m => m.ProductsModule)
      }
    ]
  }
  ,
  {
    path: 'new_arrival',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/products/products.module').then(m => m.ProductsModule)
      }
    ]
  }

  ,
  {
    path: 'new_arrivals',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/products/products.module').then(m => m.ProductsModule)
      }
    ]
  }
  ,
  {
    path: 'hot_selling',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/products/products.module').then(m => m.ProductsModule)
      }
    ]
  },

  {
    path: 'festive_collection',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/products/products.module').then(m => m.ProductsModule)
      }
    ]
  },


  {
    path: 'festive__seasion_wedding',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/products/products.module').then(m => m.ProductsModule)
      }
    ]
  },




  {
    path: 'recommended_for_you_by_price',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/products/products.module').then(m => m.ProductsModule)
      }
    ]
  },

  {
    path: 'recommended_for_you_by_city_region',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/products/products.module').then(m => m.ProductsModule)
      }
    ]
  },
  {
    path: 'featured_collection',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/products/products.module').then(m => m.ProductsModule)
      }
    ]
  },

  {
    path: 'product-details/:id',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/product-details/products-details.module').then(m => m.ProductsDetailsModule)
      }
    ]
  },


  {
    path: 'product-details/:id/:wholesaler_user_id',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/product-details/products-details.module').then(m => m.ProductsDetailsModule)
      }
    ]
  },
  {
    path: 'previewImg',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/image-preview-modal/image-preview-modal.module').then(m => m.ImagePreviewModalModule)
      }
    ]
  },

  {
    path: 'sort',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/sort/sort.module').then(m => m.SortModule)
      }
    ]
  },
  {
    path: 'filter',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/custom-filter/custom-filter.module').then(m => m.CustomFilterModule)
      }
    ]
  }, {
    path: 'filter/:id',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/custom-filter/custom-filter.module').then(m => m.CustomFilterModule)
      }
    ]
  },

  {
    path: 'cart',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/cart/cart.module').then(m => m.CartModule)
      }
    ]
  },
  {
    path: 'cart/:wholesaler_id',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/cart/cart.module').then(m => m.CartModule)
      }
    ]
  },
  {
    path: 'order-status/:id/:status',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/orders/order-success/order-success.module').then(m => m.OrderSuccessModule)
      }
    ]
  },
  {
    path: 'order-returns',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/orders/return-orders/return-orders.module').then(m => m.ReturnOrdersModule)
      }
    ]
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor(

  ) {

    //    console.table(routes);
  }

}
