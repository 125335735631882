import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { AuthService } from '../../../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../../../services/common.service';
import * as $ from "jquery";
import { IonicSelectableComponent } from 'ionic-selectable';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnInit {
  @Input("companyId") companyId;
  addressFormGroup: FormGroup;

  selectCountryCodeOptions: any;
  selectStateCodeOptions: any;
  selectCityCodeOptions: any;

  selectAreaOptions: any = {
    header: 'Select Area',
    subHeader: ''
  };
  selectSubAreaOptions: any = {
    header: 'Select Sub-Area',
    subHeader: ''
  };
  defaultAddressTypeId: any;
  constructor(
    private toastr: ToastrService,
    public common: CommonService,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
    private modalController: ModalController,
    public toastController: ToastController
  ) {
    this.address.country_id = 101;
    this.defaultAddressTypeId = 1;
  }


  company: any = {};
  ngOnInit() {
    this.getAddressTypeList();
    this.getCountriesList();
    this.company.gst_number = null;
    this.company.pan_no = null;
    this.company.aadhar_no = null;

    this.getStatesList(this.address.country_id);

  }
  countriesList: any;
  statesList: any;
  citiesList: any;
  address: any = [];
  public getCountriesList() {
    const data = {
    };

    this.spinner.show();
    this.auth.getCountriesList(data).subscribe((res: any) => {
      this.spinner.hide();
      if (!res) {
        this.toastr.error("Invalid authentication!");
        return;
      }

      if (res.status === 200) {
        // this.toastr.success(res.message);
        this.countriesList = res.data;
        this.address.country_id = 101;
      } else {
        //  this.toastr.error(res.message);
        // this.companyStep1Arr = {};
      }
    },
      (err: any) => {
        this.spinner.hide();

        if (!this.common.checkValidAuthResponseCode(err)) {
          return;
        }
        if (err.error.text) {
          this.toastr.warning(err.error.text);
        } else {
          this.toastr.warning(err.error.message);
        }
        //  this.router.navigate(['home']);
      }
    );
  }



  public getStatesList(country) {

    console.log(country);

    this.spinner.show();
    this.auth.getStatesList(country).subscribe((res: any) => {
      this.spinner.hide();
      if (!res) {
        this.toastr.error("Invalid authentication!");
        return;
      }

      if (res.status === 200) {
        // this.toastr.success(res.message);
        this.statesList = res.data;
        this.address.country_code = (+country);
        console.log(this.statesList);
      } else {
        this.statesList = [];
        //  this.toastr.error(res.message);
        // this.companyStep1Arr = {};
      }
    },
      (err: any) => {
        this.spinner.hide();

        if (!this.common.checkValidAuthResponseCode(err)) {
          return;
        }
        if (err.error.text) {
          this.toastr.warning(err.error.text);
        } else {
          this.toastr.warning(err.error.message);
        }
        //  this.router.navigate(['home']);
      }
    );
  }

  public getCitiesList(state) {

    console.log(state);

    this.spinner.show();
    this.auth.getCitiesList(state).subscribe((res: any) => {
      this.spinner.hide();
      if (!res) {
        this.toastr.error("Invalid authentication!");
        return;
      }

      if (res.status === 200) {
        // this.toastr.success(res.message);
        this.citiesList = res.data;

        console.log(this.citiesList);
      } else {
        this.citiesList = [];
        //  this.toastr.error(res.message);
        // this.companyStep1Arr = {};
      }
    },
      (err: any) => {
        this.spinner.hide();

        if (!this.common.checkValidAuthResponseCode(err)) {
          return;
        }
        if (err.error.text) {
          this.toastr.warning(err.error.text);
        } else {
          this.toastr.warning(err.error.message);
        }
        //  this.router.navigate(['home']);
      }
    );
  }

  selectedCountry = {
    id: 101,
    name: "India",
    code: 91
  };
  countryChange(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    this.selectedCountry=event.value;
    this.address.country_id=event.value.id;
    this.getStatesList(event.value.id);
  }
  selectedState:any;
  stateChange(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    this.selectedState=event.value;
    this.getCitiesList(event.value.id);
  }
  selectedCity:any;
  cityChange(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    this.selectedCity=event.value;
  }
  addressTypeList: any;
  public getAddressTypeList() {
    const data = {
    };

    this.spinner.show();
    this.auth.getAddressTypeList(data).subscribe((res: any) => {
      this.spinner.hide();
      if (!res) {
        this.toastr.error("Invalid authentication!");
        return;
      }

      if (res.status === 200) {
        this.addressTypeList = res.data;
      } else {
      }
    },
      (err: any) => {
        this.spinner.hide();

        if (!this.common.checkValidAuthResponseCode(err)) {
          return;
        }
        if (err.error.text) {
          this.toastr.warning(err.error.text);
        } else {
          this.toastr.warning(err.error.message);
        }
      }
    );


  }


  public submitShippingAddress(form: any) {
    if (!form.value) {
      this.toastr.warning("Invalid details!");
      return;
    }

    if (form.value.panNumber === "" && form.value.aadharNumber === "" && form.value.gstNumber === "") {
      this.toastr.warning("Enter atleast PAN or Aadhar or GST");
      return;
    }
  

    if (this.selectedCountry.id === 101) {
      if (form.value.pincode.length !== 6) {
        this.toastr.warning("Invalid pincode!");
        return;
      }
    }

    const data = {
      "company_id": this.companyId,
      "address_line1": form.value.address1,
      "address_line2": form.value.address2,
      "country_id": this.selectedCountry.id,
      "state_id": this.selectedState.id,
      "city_id": this.selectedCity.id,
      "pincode": form.value.pincode,
      "gst_number": form.value.gstNumber,
      "aadhar_number": form.value.aadharNumber,
      "pan_number": form.value.panNumber,
      "contact_person_name":form.value.contact_person_name
    };


    this.spinner.show();
    this.auth.addShippingAddress(data).subscribe((res: any) => {
      this.spinner.hide();
      //console.log(res);

      if (!res) {
        this.toastr.error("Invalid authentication!");
        return;
      }
      if (res.status === 200) {
        this.toastr.success(res.message);
        form.reset();
        this.dismiss();
        if (res.data) {
          //  this.setCompanyData(res);
        }
        //  this.router.navigate(['/personalDetails2']);
      } else {
        this.toastr.error(res.message);
      }
    },
      (err: any) => {
        this.spinner.hide();
        if (!this.common.checkValidAuthResponseCode(err)) {
          return;
        }
        if (err.error.text) {
          this.toastr.warning(err.error.text);
        } else {
          this.toastr.warning(err.error.message);
        }
      }
    );


  }

  // Back to previous page function
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    })
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Address Added Successfully',
      duration: 2000,
      animated: true,
      color: "success",
      position: "bottom"
    });
    toast.present();
    this.modalController.dismiss({
      'dismissed': true
    })
  }

}
