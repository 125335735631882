
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { QueryService } from 'src/app/services/query.service';
import { StoreService } from 'src/app/services/store.service';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { Router } from '@angular/router';

import { RedeemPointsComponent } from '../../components/redeem-points/redeem-points.component';
@Component({
  selector: 'app-redeem-points-otp',
  templateUrl: './redeem-points-otp.component.html',
  styleUrls: ['./redeem-points-otp.component.scss'],
})
export class RedeemPointsOTPComponent implements OnInit {

  constructor(

    private router: Router,

    private auth: AuthService,
    private store: StoreService,
    private toastr: ToastrService,
    public query: QueryService,
    private spinner: NgxSpinnerService,
    private common: CommonService,
    private modalController: ModalController,
    private theInAppBrowser: InAppBrowser) { }

  ngOnInit() {
    this.resendOtp();

  }

  redeemPoints() {
    console.log("Points Redeemed");

    const data = {
    };
    // this.router.navigate(['loyalty-points']);

    this.spinner.show();
    this.store.redeemPoints(data).subscribe((res: any) => {
      this.spinner.hide();
      if (!res) {
        this.toastr.error("Invalid authentication!");
        return;
      }
      if (res.status === 200) {
        this.toastr.success(res.message);
        this.modalController.dismiss({
          'dismissed': true
        })
      } else {
        this.toastr.error(res.message);
      }
    },
      (err: any) => {
        this.spinner.hide();

        if (!this.common.checkValidAuthResponseCode(err)) {
          return;
        }
        if (err.error.text) {
          this.toastr.warning(err.error.text);
        } else {
          this.toastr.warning(err.error.message);
        }
      }
    );


  }
  isActiveToggleTextPassword: Boolean = true;


  public toggleTextPassword(): void {
    this.isActiveToggleTextPassword = (this.isActiveToggleTextPassword == true) ? false : true;
  }

  public getType() {
    return this.isActiveToggleTextPassword ? 'password' : 'text';
  }

  public getName() {
    return this.isActiveToggleTextPassword ? 'eye-outline' : 'eye-off-outline';
  }

  async dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    })
  }


  public submitForm(otp: any) {
    console.log(otp);
    if (!otp) {
      this.toastr.warning("Invalid verification code!");
      return;
    }
    const data = {
      'otp': otp,
    };

    this.spinner.show();
    this.auth.verifyRedeemPointsOtp(data).subscribe((res: any) => {
      this.spinner.hide();

      if (!res) {
        // this.toastr.error("Invalid authentication!");
        return;
      }

      // if (res.status === 200) {
      //   this.toastr.success(res.message);
      // } else {
      //   this.toastr.error(res.message);
      // }
      if(res.ErrorMessage==='Success'){
        this.toastr.info(res.ErrorMessage);
        this.redeemPointsCall();
      }else{
        this.toastr.error(res.ErrorMessage);
      }
     
    },
      (err: any) => {
        this.spinner.hide();

        if (!this.common.checkValidAuthResponseCode(err)) {
          return;
        }

        if (err.error.text) {
          this.toastr.warning(err.error.text);
        } else {
          this.toastr.warning(err.error.message);
        }
      }
    );


  }
  redeemPointsCall() {
    this.modalController.dismiss({
      'dismissed': true
    })
    this.redeemPointsBox();
  }

  // Redeem Points
  async redeemPointsBox() {
    const modal = await this.modalController.create({
      component: RedeemPointsComponent,
      cssClass: 'my-custom-modal2'
    });
    return await modal.present();
  }
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  public resendOtp() {
    const data = {
      // 'username': this.username
    };

    this.spinner.show();
    this.auth.resendRedeemPointsOtp(data).subscribe((res: any) => {
      this.spinner.hide();

      if (!res) {
        // this.toastr.error("Invalid authentication!");
        return;
      }
      this.toastr.info(res.ErrorMessage);
      // if (res.status === 200) {
      //   this.toastr.success(res.message);

      // } else {
      //   this.toastr.error(res.message);
      // }
    },
      (err: any) => {
        this.spinner.hide();

        if (!this.common.checkValidAuthResponseCode(err)) {
          return;
        }


        if (err.error.text) {
          this.toastr.warning(err.error.text);
        } else {
          this.toastr.warning(err.error.message);
        }
      }
    );


  }


}
